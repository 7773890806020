import axios from "axios";
import {useContext, useEffect} from "react";
import {GlobalStateContext, viewStateActionTypes} from "../../store/GlobalState";
import {preloadActionTypes} from "../../store/GlobalState/actions";

export const UsePreloadRequest = () => {

    const {viewState, dispatchPreloadState, dispatchViewState} = useContext(GlobalStateContext);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const closePreloader = () => {
        if (viewState.loaders.appPreloading) {
            dispatchViewState({
                type: viewStateActionTypes.ClearAppPreloader,
            });
        }
    }
    const getPreloadData = () => {
        axios.get(`${process.env.REACT_APP_BASE_API}`, {params: {
                action: "get_contract_info",
            },
            cancelToken: source.token
        }).then((response) =>{
            closePreloader();
            if (response.data?.status_code?.toString() === '2') {
                dispatchPreloadState({
                    type: preloadActionTypes.setData,
                    payload: response.data.data,
                });
            }
        }).catch((error) => {
            closePreloader();
            console.log(error);
        })
    };

    useEffect(() => {

        return() => {
            source.cancel();
        };
    },[]);

    return{
        getPreloadData
    };
}
