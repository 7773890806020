import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {accordionData} from "./constants";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props}>
        {props.children}
    </MuiAccordion>
))(({ theme }) => ({
    border: `0px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon style={{color:'#DE8FFE'}} sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(29, 12, 56, 1)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(222, 143, 254, 1)',
}));

export const HelpDeskAccordion = () => {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div>
            {
                accordionData.map((item, key) => {

                    return(
                        <Accordion key={`${item.id}--${key}`} expanded={expanded === `panel-${item.id}-${key}`} onChange={handleChange(`panel-${item.id}-${key}`)}>
                            <AccordionSummary aria-controls={`${item.id}-controls-${key}`} id={`${item.id}-${key}`} style={{textAlign: 'left'}}>
                                <Typography style={{color:'#DE8FFE'}}>{item.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{backgroundColor:'#1D0C38'}}>
                                <Typography style={{color:'#DE8FFE',textAlign:'left'}}>{item.description}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    )

                })
            }
        </div>
    );
}
