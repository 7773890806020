import styled from "@emotion/styled";

export const TopIcon = styled.img`
  width: 80%;
  margin-top: -120px;

  @media (max-width: 700px) {
    margin-top: -80px;
  }
  
  @media (max-width: 400px) {
    margin-top: -50px;
  }
`
