import twitterIcon from '../assets/images/social/twitter.svg';
import teleIcon from '../assets/images/social/telegram.svg';
import openIcon from '../assets/images/social/opensea.svg';
import discordIcon from '../assets/images/social/discord.svg';

export const websiteEmail = 'info@bmlc.io';

export const availableNetworkId = '1';
export const smartContractAddress = '0xA779eaa46FBDa629ffa1bCEeEbd559C89E0C9fBC';
export const cidImageAddress = 'QmZBMQHA6qSivAj1jfij3ZiJjdKq3NFQXu2jc3g8ErzCFn';
export const testNetAddress = '';
export const whitePaperURL = 'https://bmlc.io/whitepaper.pdf';
export const gaId = 'G-144JR6E952';
export const gTagId = 'GTM-58MHRVX';

export const smartContractConfig = {
    name: 'Verified Smart Contract',
    description: smartContractAddress,
    url: `https://etherscan.io/address/${smartContractAddress}#code`,
};

export const PATH_SMART_CONTRACT = `https://${testNetAddress}opensea.io/assets/${smartContractAddress}/`;
export const PATH_NFT_URL = `https://ipfs.io/ipfs/${cidImageAddress}/`;

export const socials = [
    {
        icon: twitterIcon,
        alt: 'twitter-icon',
        name: 'Twitter',
        url: 'https://twitter.com/BoogieMoons',
    },
    {
        icon: teleIcon,
        alt: 'telegram-icon',
        name: 'Telegram',
        url: 'https://t.me/+aJHrZASiI45kMDVh',
    },
    {
        icon: openIcon,
        alt: 'opensea-icon',
        name: 'OpenSea',
        url: 'https://opensea.io/collection/boredtoiletpaperclub',
    },
    {
        icon: discordIcon,
        alt: 'discord-icon',
        name: 'Discord',
        url: 'https://discord.gg/4KyWPgBnuy',
    }
];
