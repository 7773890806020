import React from 'react';
import { Grid } from '@mui/material';

import svg_white_arrow_down from '../../../assets/images/white-arrow-down.svg';

import {BottomContainer} from "./components/BottomContainer";
import {ArrowContainer} from "./components/ArrowContainer";
import {SocialBlock} from "../../shared/SocialBlock";
import {websiteEmail, whitePaperURL} from "../../../config/websiteConfig";
import {SmartContractButton} from "./components/SmartContractButton";


const fontColorArr = {white: '#FFFFFF', yellow: '#DEFF00', pink: '#DE8FFE'};

export const PrimaryFooter = () => {

    return(
        <Grid container columns={12} maxWidth={800} >
            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                <div style={{paddingTop:60, fontSize:30, fontWeight: 1000 }}>
                    <span style={{color:fontColorArr.pink}}>FIND US</span>&nbsp;<span style={{color: fontColorArr.white}}>HERE</span>
                </div>
            </Grid>
            <ArrowContainer>
                <img src={svg_white_arrow_down} alt="arrow-icon" />
            </ArrowContainer>
            <BottomContainer>
                <div className='social-icons-wrapper'>
                    <SocialBlock />
                    <SmartContractButton />
                    <div style={{marginTop: '1em', color: '#ec86ff'}}>
                        <a href={whitePaperURL} target='_blank' rel="noopener noreferrer">White Paper</a>
                    </div>
                    <div style={{marginTop: '1em', color: '#ec86ff'}}>
                        <a href={`mailto:${websiteEmail}`} rel="noopener noreferrer">{websiteEmail}</a>
                    </div>
                </div>
                <span className="copyright" style={{fontSize:17, color: fontColorArr.pink, width:'100%', fontWeight: 900}}>
                      © {new Date().getFullYear()} BoogieM<span style={{color: fontColorArr.white}}>oo</span>ns
                    </span>
            </BottomContainer>
        </Grid>
    );
}
