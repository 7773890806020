import {useContext, useEffect} from "react";
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import { GlobalStateContext, viewStateActionTypes } from '../../store/GlobalState';
import {UseEthConnect} from "../UseEthConnect";
import {DeviceTypes} from "../../config/uiConfig";
import {UsePreloadRequest} from "./UsePreloadRequest";
import {RouterListener} from "../RouterListener";

export const AppPreloader = () => {
    const { clientState, dispatchViewState } = useContext(GlobalStateContext);

    const { getPreloadData } = UsePreloadRequest();

    const {handleConnect} = UseEthConnect();

    const getDeviceSizes = () => {
        const screenWidth = Number(window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth);

        const screenHeight = Number(window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight);
        dispatchViewState({
            type: viewStateActionTypes.SetDeviceSize,
            payload: {
                width: screenWidth,
                height: screenHeight,
            }
        })
    }

    useEffect(() => {
        if (clientState.isLoggedIn === false) {
            getPreloadData();
        }
    }, [clientState.isLoggedIn])

    useEffect(() => {
        if (isMobile || isAndroid || isIOS) {
            dispatchViewState({
                type: viewStateActionTypes.SetDeviceType,
                payload: DeviceTypes.Mobile,
            });
        }
        window.addEventListener('resize', () => {
            getDeviceSizes();
        });
        getDeviceSizes();
        if (clientState.isLoggedIn) {
            handleConnect();
            dispatchViewState({
                type: viewStateActionTypes.ClearAppPreloader,
            });
        }
    }, []);

    return <RouterListener />;
}
