import styled from '@emotion/styled';

export const BaseLayout = styled.div`
  margin: 0;
  min-height: 100%;
  height: auto;
  background-color: ${({ theme }) => theme.palette.primary.backgroundBody};
  color: ${({ theme }) => theme.palette.primary.textPrimary};
  position: relative;
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
  flex-direction: row;
`
