import styled from "@emotion/styled";
import {backgroundColors} from "../../../../config/backgroundConfig";

export const Button = styled.button`
  padding: 0.5em 1.2em;
  margin: 0.2em;
  color: white;
  border: 2px solid white;
  font-size: 1.5em;
  border-radius: 5px;
  text-transform: uppercase;
  background-color: ${backgroundColors.PinkDefault};
  cursor: pointer;
  font-weight: bold;
  box-shadow: 6px 6px 12px rgba(0,0,0,0.7);
  
  @media (max-width: 380px) {
    font-size: 1em;
  }
`;
