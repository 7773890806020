import React from 'react';
import { PrimarySection } from '../../shared/Section';
import {Title} from "../../shared/Title";
import {backgroundColors} from "../../../config/backgroundConfig";
import {CollectionItem} from "./CollectionItem";
import {upcomingCollectionObjects} from "./constants";

export const UpcomingCollections = () => {

    return(
        <>
            <PrimarySection
                backgroundColor="transparent"
                withoutContentBackground={true}
            >
                <Title>
                    <div
                        style={{
                            marginBottom: '1em',
                            marginTop: '1em',
                        }}
                    >
                        <span style={{color: backgroundColors.PinkLight}}>UPCOMING</span> COLLECTIONS
                    </div>
                </Title>
            </PrimarySection>
            <PrimarySection
                backgroundColor="transparent"
                withoutContentBackground={true}
            >
                {
                    upcomingCollectionObjects.map((item, key) => (
                        <CollectionItem item={item} key={`${item.id}-${key}`} />
                    ))
                }
            </PrimarySection>
        </>
    );
};
