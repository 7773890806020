import React, {useContext} from "react";
import {Container} from "./components/Container";
import {GlobalStateContext} from "../../../store/GlobalState";
import {MessageModal, ModalTransfer} from "../../shared/modals";
import {ModalTypes} from "../../../config/modalConfig";

export const ModalWrapper = () => {

    const {viewState} = useContext(GlobalStateContext);

    return(
        <>
            {
                viewState.modal.isOpen &&
                <Container>
                    {
                        viewState.modal.type === ModalTypes.MessageModal &&
                        <MessageModal />
                    }
                    {
                        viewState.modal.type === ModalTypes.TransactionModal &&
                        <ModalTransfer />
                    }
                </Container>
            }
        </>
    );
}
