import styled from "@emotion/styled";

export const TitleWIthIcon = styled.div`
  /*
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80%;
  margin-left: 10%;
   */
  max-width: 80%;
  margin-left: 10%;
  display: grid;
  grid-template-columns: 2fr 0.5fr 1fr;
  align-items: center;
  
  
  &> .point-title {
    text-align: left;
  }

  &> .icon-arrow {
    flex: 1;
    width: 70%;
    max-width: 100px;
    margin-top: 2em;
    justify-content: center;
    
    img {
      width: 100%;
      height: auto;
    }
  }
  
  &> .icon-wrapper {
    display: flex;
    flex: 1;
    width: 100%;
    max-width: 100px;
    
    img {
      width: 100%;
      max-width: 100px;
      height: 50px
    }
  }
`
