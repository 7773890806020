import React, {useContext, useState} from "react";
import {Button, TextField} from "@mui/material";
import {backgroundColors} from "../../../config/backgroundConfig";
import {Content} from "./components/Content";
import {Container} from "./components/Container";
import {GlobalStateContext, viewStateActionTypes} from "../../../store/GlobalState";
import {processActionTypes} from "../../../store/GlobalState/actions";
import {PROCESS_ACTION_TYPES} from "../../../config/actionConfig";

export const ModalTransfer = () => {

    const [error, setError] = useState('');

    const {
        dispatchViewState,
        transAction,
        dispatchTransAction,
    } = useContext(GlobalStateContext);

    const isValidAddress = (adr) => {
        try {
            //const web3 = new Web3()
            window.web3.utils.toChecksumAddress(adr)
            return true
        } catch (e) {
            return false
        }
    }

    const submitForm = (event) => {
        event.preventDefault();
        if (typeof event.target.transferId.value === 'string' && isValidAddress(event.target.transferId.value)) {
            dispatchTransAction({
                type: processActionTypes.SetTransferId,
                payload: event.target.transferId.value,
            });
            dispatchTransAction({
                type: processActionTypes.AddAction,
                payload: PROCESS_ACTION_TYPES.ReadyToRequest,
            });
            setError('');
        }
        else {
            setError('Please enter a valid address.');
        }
    };

    return(
        <Container>
            <h1><span style={{color: backgroundColors.PinkLight}}>TRANSFER</span> TOKEN ID</h1>
            <p>#{transAction.id}</p>
            <Content>
                <form onSubmit={submitForm} style={{width: '100%'}}>
                    <div
                        style={{margin: '0 auto'}}
                    >
                    <div>
                        <TextField
                            name="transferId"
                            title="Transfer Id"
                            variant="outlined"
                            size='small'
                            style={{
                                borderColor: backgroundColors.PinkLight,
                                borderRadius: "5px",
                                backgroundColor: "#FFF"
                            }}
                            error={!!error}
                        />
                        {
                            error &&
                            <div style={{
                                color: '#FF0000',
                                fontWeight: '300',
                                fontSize: 'smaller',
                            }}>{error}</div>
                        }
                    </div>
                        <div style={{display: 'flex',justifyContent: 'space-between', marginTop: '2em',}}>
                            <Button type="button" variant="filled" style={{backgroundColor:'#000000'}} onClick={() => {
                                dispatchTransAction({
                                    type: processActionTypes.AddAction,
                                    payload: PROCESS_ACTION_TYPES.Ready,
                                });
                                dispatchViewState({
                                    type: viewStateActionTypes.CloseAppModal
                                });
                            }}>CANCEL</Button>
                            <Button type="submit" variant="filled" style={{backgroundColor: backgroundColors.PinkLight}}  >SUBMIT</Button>
                        </div>
                    </div>
                </form>
            </Content>
        </Container>
    );
}
