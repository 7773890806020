import React, { useContext } from 'react';
import styled from "@emotion/styled";

import { GlobalStateContext, viewStateActionTypes } from '../../../store/GlobalState';

const ButtonWrapper = styled.div`
  position: sticky;
  top: 180px;
  left: 40px;
  width: 100%;
  margin-top: -40px;
  z-index: 100;
  @media(max-width: 700px) {
    top: 160px;
  }
`;

const ButtonDiv = styled.div`
  position: absolute;
  z-index: 70;
  width: 15%;
  max-width: 120px;
  cursor: pointer;
  top: -40px;
  right: 40px;
  
  @media(max-width: 700px) {
    right: 20px;
  }
`;


export const Button = () => {

    const {
        viewState,
        dispatchViewState,
    } = useContext(GlobalStateContext);

  return(
      <ButtonWrapper>
          <ButtonDiv
              onClick={
                  () => {
                      dispatchViewState({
                          type: viewStateActionTypes.SetIsReadDocumentation,
                          payload: !viewState.pages.isReadDocumentation,
                      })
                  }
              }>
              <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 60 60"
                  x={30}
                  y={30}
              >
                  <rect
                      fill="#FFFFFF"
                      x="0"
                      y="0"
                      width="60"
                      height="60"
                  />
                  <rect
                      fill="#EB008b"
                      x="2"
                      y="2"
                      width="56"
                      height="56"
                  />
                  <rect
                      fill="#000000"
                      x="12.5"
                      y="12.5"
                      width="35"
                      height="35"
                  />
                  <g fill="#EB008b">
                      {
                          viewState.pages.isReadDocumentation &&
                          <g>
                              <rect x="12.42" y="36.68" width="35.14" height="2.03"/>
                              <rect x="21" y="22.75" width="3" height="3.4"/>
                              <rect x="36" y="22.75" width="3" height="3.4"/>
                          </g>
                      }
                      {
                          !viewState.pages.isReadDocumentation &&
                          <g>
                              <rect
                                  x="22.39"
                                  y="29.7"
                                  width="15.2"
                                  height="1.3"
                                  transform="matrix(0.7071 0.7071 -0.7071 0.7071 30.2428 -12.322)"
                              />
                              <rect
                                  x="22.39"
                                  y="29.7"
                                  width="15.2"
                                  height="1.3"
                                  transform="matrix(0.7071 -0.7071 0.7071 0.7071 -12.6719 30.0979)"
                              />
                          </g>
                      }
                  </g>
              </svg>
          </ButtonDiv>
      </ButtonWrapper>
  );
};
