import React, {useContext} from 'react';
import { PrimarySection } from '../../shared/Section';
import {backgroundColors} from "../../../config/backgroundConfig";
import {Title} from "../../shared/Title";

import {GlobalStateContext} from "../../../store/GlobalState";
import {CardItem} from "./components/CardItem";
import {CardContainer} from "./components/CardContainer";
import {TotalBlock} from "../JoinUs/components/TotalBlock";
import {PriceBlock} from "../JoinUs/components/PriceBlock";
import tritoilIcon from "../../../assets/images/mint/tritoil.svg";
import {BottomContainer} from "./components/BottomContainer";
import {PATH_NFT_URL, PATH_SMART_CONTRACT} from "../../../config/websiteConfig";

export const MyRoom = () => {

    const {clientState} = useContext(GlobalStateContext);

    return(
        <>
            <PrimarySection
                backgroundColor="#20093a"
                contendBackgroundColor={backgroundColors.YellowDefault}
                zIndex={3}
            >
                <Title>
                    MY ROOM
                </Title>

                <CardContainer>
                    {
                        clientState.smart.walletOfOwner?.map((item, key) => (
                            <CardItem
                                key={`${key}----${item}`}
                                image={`${PATH_NFT_URL}${item}.png`}
                                number={item}
                                name={`#${item}`}
                                sellPath={`${PATH_SMART_CONTRACT}${item}`}
                            />
                        ))
                    }
                </CardContainer>

                <PrimarySection
                    backgroundColor="transparent"
                    contendBackgroundColor={backgroundColors.PinkDefault}
                    marginBottom={100}
                >
                    <BottomContainer>
                        <img src={tritoilIcon} alt="tritoil icon" />
                        <div className='buttons-wrapper'>
                            <PriceBlock />
                            <TotalBlock />
                        </div>
                    </BottomContainer>
                </PrimarySection>
            </PrimarySection>
        </>
    );
}
