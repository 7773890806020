import React from "react";
import {Button} from "./components/Button";

export const PrimarySubmitButton = (props) => {

    const {onClick, children} = props;

    return(
      <Button
          { ...props}
          onClick={() => {
            if (onClick) {
              onClick();
            }
        }}
      >{children}</Button>
    );
}
