import styled from '@emotion/styled';
import {backgroundColors} from "../../../../config/backgroundConfig";

export const SmartContactWrapper = styled.div`
  margin-top: 1em;
  color: ${backgroundColors.PinkLight};
  
  &> a {
    &> .title {

    }
    &> .description {
      font-size: 0.6em;
    }
  }
`;
