import styled from "@emotion/styled";

export const BottomContainer = styled.div`
  display: grid;
  text-align: center;
  grid-template-columns: 2fr 1fr;
  padding: 1em;
  grid-gap: 2em;
  align-items: center;
  
  &> .buttons-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2em;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
    padding-bottom: 2em;
  }

  @media (max-width: 400px) {
    grid-template-columns: 1fr;
    padding-bottom: 2em;
  }
`
