import React, {useContext} from "react";
import {GlobalStateContext} from "../../../../store/GlobalState";
import {InputStyledBlock} from "./InputStyledBlock";

export const TotalBlock = (props) => {

    const {clientState, preloadState} = useContext(GlobalStateContext);

    return(
        <div style={props.style ? props.style : {}}>
            <InputStyledBlock
                secondary
                style={{
                    color: 'white',
                }}
            >
                {
                    clientState.smart?.totalSupply && clientState.smart?.maxSupply &&
                    <><span>{Number(Number(clientState.smart?.maxSupply) - Number(clientState.smart?.totalSupply))}</span> / {clientState.smart?.maxSupply}</>
                }
                {
                    !clientState.smart?.totalSupply && !clientState.smart?.maxSupply &&
                    preloadState.totalSupply && preloadState.maxSupply &&
                    <><span>{Number(Number(preloadState.maxSupply) - Number(preloadState.totalSupply))}</span> / {preloadState.maxSupply}</>
                }
            </InputStyledBlock>
            <div style={{marginTop: '1em'}}>
                Boogies club pass Left
            </div>
        </div>
    );
}
