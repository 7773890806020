import styled from "@emotion/styled";

export const CardContainer = styled.div`
  display: grid;
  text-align: center;
  grid-template-columns: 1fr 1fr;
  padding: 1em;
  grid-gap: 1em;

  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 400px) {
    grid-template-columns: 1fr;
  }
`
