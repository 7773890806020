import React, {useContext} from "react";
import {Container} from "./components/Container";
import {GlobalStateContext} from "../../../store/GlobalState";
import {PrimaryLoader} from "../../shared/loaders/PrimaryLoader";

export const LoaderWrapper = () => {

    const {viewState} = useContext(GlobalStateContext);

    return(
        <>
            {
                viewState.loaders.appLoader &&
                <Container>
                    <PrimaryLoader />
                </Container>
            }
        </>
    );
}
