import styled from "@emotion/styled";

export const BottomRightWrapper = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: 1em;
  &> img {
    max-width: 30%;
    width: 100%;
  }
  @media (min-width: 700px) {
    margin-top: -1.5em;
    text-align: right;
  }
`
