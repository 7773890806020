import React from 'react';

export const ErrorPage = () => {

    return(
      <h1>
          404
      </h1>
    );
};
