import React, {useContext, useEffect, useRef, useState} from 'react';

import { PrimarySection } from '../../shared/Section';
import {GlobalStateContext} from "../../../store/GlobalState";

import decentralizedIcon from '../../../assets/images/titles/decentralized.svg';
import {backgroundColors} from "../../../config/backgroundConfig";

export const ReadDocumentation = () => {

    const {viewState} = useContext(GlobalStateContext);
    const myRef = useRef(null);
    const [isFirst, setIsFirst] = useState(true);

    useEffect(() => {
        if (!viewState.pages.isReadDocumentation && myRef?.current && !isFirst) {
            myRef?.current?.scrollIntoView({ behavior: 'smooth'});
        }
        setIsFirst(false);
    },[viewState.pages.isReadDocumentation]);

    return(
        <>
        <div
            style={{
                height: "100px"
            }}
            ref={myRef}
        />
            {
                !viewState.pages.isReadDocumentation &&

                <PrimarySection
                    contentBackgroundItem={backgroundColors.gradientPurple}
                >
                    <div
                        style={{
                            padding: '2em 1em',
                        }}
                        >
                        <div
                            style={{
                                color: '#FFF',
                                padding: '1em',
                                backgroundColor: 'rgba(0,0,0,0.4)',
                            }}
                        >
                            <h1>DEAR FRIENDS</h1>
                            <p>
                                Congratulations on taking the first step in becoming a member of our NFT community. Considering how you ended up on this page, You can also become our like-minded person, friend and partner.
                            </p>
                            <div>
                                <img src={decentralizedIcon} alt="decentralized-title" />
                            </div>
                            <p>
                                Together we will have an optimal and proficient access to the world of NFT. You will be able to learn and grow with us in a community of highly skilled professionals.
                            </p>
                            <p>
                                As a member you will have privileged access to own a BoogieMoons NFT and presale information and updates.
                            </p>
                            <p>
                                And We are NOT financial advisers. BTW
                            </p>
                        </div>
                    </div>
                </PrimarySection>
            }
        </>
    );
};
