import React from 'react';

import {BackgroundWrapper} from "./BackgroundWrapper";

const BackgroundTop = ({className, backgroundColor, backgroundItem}) => (
    <div
        className={className}
    >
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 400 40"
            style={{
                enableBackground: 'new 0 0 400 40',
            }}
        >
            {
                backgroundItem &&
                <backgroundItem.svgGradient />
            }
            <polygon
                fill={
                    backgroundItem ? `url(#${backgroundItem.id})` : backgroundColor
                }
                points="272.42,16.07 194.92,17.39 93.14,22.26 0,10.51 0,40 400,40 400,0 326.32,10.51 "
            />
        </svg>
    </div>
);

const BackgroundMiddle = ({className, backgroundColor, backgroundItem}) => (
    <div
        className={className}
        style={{
            background: backgroundItem ? `url(${backgroundItem.svg})` : backgroundColor,
            backgroundPosition: '100% auto', // todo iphone background
        }}
    />
);

const BackgroundBottom = ({className, backgroundColor, backgroundItem}) => (
    <div
        className={className}
    >
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 400 40"
            style={{
                enableBackground: 'new 0 0 400 40',
            }}
        >
            <polygon
                fill={
                    backgroundItem ? `url(#${backgroundItem.id})` : backgroundColor
                }
                points="0,0 0,32.56 31.97,36.18 192.25,15.09 233.07,22.39 356.61,17.41 400,17.41 400,0 "
            />
        </svg>
    </div>
);


export const BackgroundPrimary = ({backgroundColor, backgroundItem}) => {

    return(
        <BackgroundWrapper>
            <div className="background-blocks">
                <BackgroundTop className="background-top" backgroundColor={backgroundColor || "#ffc614"} backgroundItem={backgroundItem} />
                <BackgroundMiddle className="background-middle" backgroundColor={backgroundColor || "#ffc614"} backgroundItem={backgroundItem}/>
                <BackgroundBottom className="background-bottom" backgroundColor={backgroundColor || "#ffc614"} backgroundItem={backgroundItem}/>
            </div>
        </BackgroundWrapper>
    );
}
