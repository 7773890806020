import styled from "@emotion/styled";

export const TopLeftWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  

  @media (min-width: 700px) {
    position: absolute;
    max-width: 30%;
  }
  
  @media (max-width: 699px) {
    text-align: center;
    &> img {
      margin-left: -40px;
      max-width: 40%;
    }

  }
`
