import React from 'react';
import styled from '@emotion/styled';
import {Description} from "./Description";

const CardHead = () => {

    return(
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
        >
            <rect y="0" className="st0" width="50" height="50"/>
            <g fill="#FFF">
                <rect y="34.92" className="st1" width="50" height="2.71"/>
                <rect x="15.64" y="22.81" className="st1" width="4.38" height="4.38"/>
                <rect x="31.85" y="22.81" className="st1" width="4.38" height="4.38"/>
            </g>
        </svg>
    );
}

const CardBackgroundTop = () => {

    return(
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 400 60"
        >
            <polygon fill="#FFF" points="321.4,3.84 162.65,11.47 147.31,3.76 0.32,4.25 0.32,60 400.32,60 400.32,6.35 "/>
        </svg>
    )
}

const CardBackgroundBottom = () => {

    return(
        <div style={{marginTop: '-1px',}}>
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 400 60"
            >
                <polygon fill="#FFF" points="32.3,34.45 356.93,28.04 400.32,28.04 400.32,0 0.32,0 0.32,28.04 "/>
            </svg>
        </div>
    )
}

const CardWrapper = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 4.7em;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const CardContent = styled.div`
  width: 100%;
  margin-top: -5px;
  background-color: white;
  text-align: left;
`;

const ImageWrapper = styled.div`
  width: 80%;
  margin: 1em 10%;
  
  &> img {
    width: 100%;
    height: auto;
  }
`;

const CardHeadWrapper = styled.span`
  width: 30px;
`;

const CardDescription = styled.div`
  margin: 0.5em;
`;

const CardTitle = styled.span`
  display: grid;
  grid-template-columns: 30px auto;
  grid-gap: 0.5em;
`;

export const CardItem = ({image, name, decription}) => {

    return(
        <CardWrapper>
            <CardBackgroundTop />
            <CardContent>
                <ImageWrapper>
                    {
                        image &&
                        <img src={image} alt="card item" />
                    }
                </ImageWrapper>
                <CardDescription>
                    <CardTitle>
                        <CardHeadWrapper>
                            <CardHead />
                        </CardHeadWrapper>
                        <div>{name}</div>
                    </CardTitle>
                </CardDescription>
            </CardContent>
            <CardBackgroundBottom />
            <Description style={{marginTop: `calc(${Math.random()*32}px - 8px)`}}>{decription}</Description>
        </CardWrapper>
    );
}
