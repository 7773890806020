import {createContext, useReducer} from "react";

import {
    clientStateReducer,
    preloadStateReducer,
    processStatesReducer,
    rewardStateReducer,
    viewStateReducer
} from "./reducer";
import {
    initialClientState,
    initialPreloadState,
    initialProcessState,
    initialRewardState,
    initialViewState
} from "./initialValue";

export const GlobalStateContext = createContext({});

export const GlobalStateProvider = ({ children }) => {
    const [clientState, dispatchClientState] = useReducer(
        clientStateReducer,
        initialClientState
    );
    const [mintAction, dispatchMintAction] = useReducer(processStatesReducer, initialProcessState);
    const [transAction, dispatchTransAction] = useReducer(processStatesReducer, initialProcessState);
    const [preloadState, dispatchPreloadState] = useReducer(preloadStateReducer, initialPreloadState);
    const [viewState, dispatchViewState] = useReducer(
        viewStateReducer,
        initialViewState
    );
    const [rewardState, dispatchRewardState] = useReducer(
        rewardStateReducer,
        initialRewardState
    );

    return(
        <GlobalStateContext.Provider value={{
            clientState,
            dispatchClientState,
            viewState,
            dispatchViewState,
            rewardState,
            dispatchRewardState,
            mintAction,
            dispatchMintAction,
            transAction,
            dispatchTransAction,
            preloadState,
            dispatchPreloadState,
        }}>
            {
                children
            }
        </GlobalStateContext.Provider>
    );
}
