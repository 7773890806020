import {
    clientStateActionTypes,
    preloadActionTypes,
    processActionTypes,
    rewardStateActionTypes,
    viewStateActionTypes
} from "./actions";
import localState from "../../services/localState";
import {initialProcessState, initialViewState} from "./initialValue";

export const clientStateReducer  = (state, action) => {
    switch (action.type) {

        case clientStateActionTypes.SetId:
            localState.setLocalId(action.payload);
            return { ...state, localId: action.payload };

        case clientStateActionTypes.ClearId:
            localState.removeLocalId();
            return { ...state, localId: '' };

        case clientStateActionTypes.SetLoggedIn:
            localState.setLoggedIn(action.payload);
            return { ...state, isLoggedIn: action.payload};

        case clientStateActionTypes.SetIsHaveNFT:
            return { ...state, isHaveNFT: action.payload};

        case clientStateActionTypes.ClearLoggedIn:
            localState.setLoggedIn(false);
            return { ...state, isLoggedIn: false};

        case clientStateActionTypes.SetAccount:
            return { ...state, bc: { ...state.bc, account: action.payload} };

        case clientStateActionTypes.SetNetworkId:
            return { ...state, bc: { ...state.bc, networkId: action.payload} };

        case clientStateActionTypes.SetEthAccount:
            return { ...state, bc: { ...state.bc, ...action.payload} };

        case clientStateActionTypes.SetSmart:
            return { ...state, smart: { ...action.payload} };

        case clientStateActionTypes.ClearSmart:
            return { ...state, smart: {} };

        case clientStateActionTypes.MintAction:
            return { ...state, mintAction: {
                    type: action.payload,
                }
            };

        default:
            return state;
    }
}

export const viewStateReducer  = (state, action) => {
    switch (action.type) {

        case viewStateActionTypes.SetDeviceType:
            return { ...state, deviceType: action.payload };

        case viewStateActionTypes.SetDeviceSize:
            return {
                ...state,
                deviceSize: {
                    width: action.payload.width,
                    height: action.payload.height
                }
            };

        case viewStateActionTypes.SetIsReadDocumentation:
            localState.setIsReadDescription(action.payload);
            return {
                ...state,
                pages: {
                    ...state.pages,
                    isReadDocumentation: action.payload
                }
            };

        case viewStateActionTypes.SetIsFirstVisit:
            localState.setIsFirstVisit(action.payload);
            return {
                ...state,
                pages: {
                    ...state.pages,
                    isFirstTime: action.payload
                }
            };

        case viewStateActionTypes.OpenAppLoader:
            return {
                ...state, loaders: { ...state.loaders, appLoader: true, appLoaderMessage: action.payload ? action.payload : '' },
            };

        case viewStateActionTypes.CloseAppLoader:
            return {
                ...state, loaders: { ...state.loaders, appLoader: false, appLoaderMessage: '' },
            };

        case viewStateActionTypes.SetAppPreloading:
            return {
                ...state, loaders: { ...state.loaders, appPreloading: action.payload },
            };

        case viewStateActionTypes.ClearAppPreloader:
            return {
                ...state, loaders: { ...state.loaders, appPreloading: false },
            };

        case viewStateActionTypes.OpenAppModal:
            return {
                ...state, modal: {
                    ...state.modal,
                    ...action.payload,
                    isOpen: true,
                },
            };

        case viewStateActionTypes.CloseAppModal:
            return {
                ...state, modal: {...initialViewState.modal},
            };

        default:
            return state;
    }
}

export const processStatesReducer = (state, action) => {
    switch (action.type) {

        case processActionTypes.AddAction:
            return { ...state, type: action.payload };

        case processActionTypes.SetId:
            return { ...state, id: action.payload};

        case processActionTypes.SetTransferId:
            return { ...state, transferId: action.payload};

        case processActionTypes.ClearId:
            return { ...state, id: undefined};

        case processActionTypes.ClearTransferId:
            return { ...state, transferId: undefined};

        case processActionTypes.ClearAction:
            return { ...initialProcessState };

        default:
            return state;
    }
};

export const rewardStateReducer = (state, action) => {
    switch (action.type) {

        case rewardStateActionTypes.SetReward:
            return {
                ...state, reward: action.payload,
            };

        case rewardStateActionTypes.SetCommunity:
            return {
                ...state, community: action.payload,
            };

        case rewardStateActionTypes.SetEarly:
            return {
                ...state, early: action.payload,
            };

        case rewardStateActionTypes.SetReserved:
            return {
                ...state, reserved: action.payload,
            };

        case rewardStateActionTypes.SetNFTFree:
            return {
                ...state, nftFree: action.payload,
            };

        case rewardStateActionTypes.UpdateAllRewards:
            return {
                ...state, ...action.payload,
            }

        case rewardStateActionTypes.ClearState:
            return {
                reward: false,
                community: false,
                early: false,
                reserved: false,
                nftFree: false,
            };

        default:
            return state;
    }
}

export const preloadStateReducer = (state, action) => {
    switch (action.type) {

        case preloadActionTypes.setData:
            return {
                ...action.payload
            };

        case preloadActionTypes.clearData:
            return {};

        default:
            return state;
    }
}
