import {useContext, useEffect, useState} from "react";
import Web3 from "web3";
import {
    clientStateActionTypes, GlobalStateContext, rewardStateActionTypes, viewStateActionTypes
} from "../store/GlobalState";

import nftJson from '../abis/135/NFT.json';
import {LoaderMessages, PROCESS_ACTION_TYPES} from "../config/actionConfig";
import {processActionTypes} from "../store/GlobalState/actions";
import {ModalTypes} from "../config/modalConfig";
import {UseCallReferral} from "./UseCallReferral";

export const EtheriumInitial = () => {

    const {
        clientState,
        mintAction,
        transAction,
        dispatchClientState,
        dispatchViewState,
        dispatchMintAction,
        dispatchTransAction,
        dispatchRewardState,
    } = useContext(GlobalStateContext);

    const { callReferral } = UseCallReferral();
    const [ localContractConnection, setLocalContractConnection ] = useState({});

    const transactionInit = async () => {

        let transactionHash;

        dispatchViewState({type: viewStateActionTypes.OpenAppLoader, payload: LoaderMessages.trans.ConfirmProcess});

        await localContractConnection?.methods?.safeTransferFrom(clientState.bc.account, transAction.transferId, transAction.id)
            //.mint(bc.account, mintAmount)
            .send({ from: clientState.bc.account})
            .on('transactionHash', (hash) => {
                transactionHash = hash;
                dispatchTransAction({
                    type: processActionTypes.AddAction,
                    payload: PROCESS_ACTION_TYPES.ReadyToConfirm,
                });
                dispatchViewState({type: viewStateActionTypes.OpenAppLoader, payload: LoaderMessages.trans.ProcessMessage});
            }).once('error', (error) =>{
                dispatchViewState({
                    type: viewStateActionTypes.OpenAppModal,
                    payload: {
                        type: ModalTypes.MessageModal,
                        title: 'Error',
                        message: error.message,
                    },
                });
                dispatchTransAction({
                    type: processActionTypes.AddAction,
                    payload: PROCESS_ACTION_TYPES.Ready,
                });
                dispatchViewState({type: viewStateActionTypes.CloseAppLoader});
            }).once('receipt', () => {
                //setLoading(false);
                /*
                dispatchTransAction({
                    type: processActionTypes.AddAction,
                    payload: PROCESS_ACTION_TYPES.PreConfirmation,
                });*/
            }).once("confirmation", () => {
                dispatchTransAction({
                    type: processActionTypes.AddAction,
                    payload: PROCESS_ACTION_TYPES.ConfirmLoading,
                });
                dispatchViewState({
                    type: viewStateActionTypes.OpenAppModal,
                    payload: {
                        type: ModalTypes.MessageModal,
                        title: 'Success',
                        message: LoaderMessages.trans.SuccessMessage,
                    },
                });
                dispatchViewState({type: viewStateActionTypes.CloseAppLoader});
            });

    }

    const mintInit = async () => {
        let sendValue = 0;

        if (clientState.smart.owner.toString() !== clientState.bc.account.toString() /*todo Please add here whitelisted_res when it will be ready && whitelist_res === false*/) {
            sendValue = clientState.smart.cost;
        }

        let transactionHash;

        dispatchViewState({type: viewStateActionTypes.OpenAppLoader, payload: LoaderMessages.mint.ConfirmProcess});

        await localContractConnection?.methods?.mint(1, clientState.localId || '')
            //.mint(bc.account, mintAmount)
            .send({ from: clientState.bc.account, value: sendValue })
            .on('transactionHash', (hash) => {
                transactionHash = hash;
                dispatchMintAction({
                    type: processActionTypes.AddAction,
                    payload: PROCESS_ACTION_TYPES.ReadyToRequest,
                });
                dispatchViewState({type: viewStateActionTypes.OpenAppLoader, payload: LoaderMessages.mint.ProcessMessage});
            }).once('error', (error) =>{
                dispatchViewState({
                    type: viewStateActionTypes.OpenAppModal,
                    payload: {
                        type: ModalTypes.MessageModal,
                        title: 'Error',
                        message: error.message,
                    },
                });
                dispatchMintAction({
                    type: processActionTypes.AddAction,
                    payload: PROCESS_ACTION_TYPES.Ready,
                });
                dispatchViewState({type: viewStateActionTypes.CloseAppLoader});
            }).once('receipt', () => {
                //setLoading(false);
                /*dispatchMintAction({
                    type: processActionTypes.AddAction,
                    payload: PROCESS_ACTION_TYPES.PreConfirmation,
                });*/
            }).once("confirmation", () => {
                callReferral(transactionHash);
                dispatchMintAction({
                    type: processActionTypes.AddAction,
                    payload: PROCESS_ACTION_TYPES.ConfirmLoading,
                });
                dispatchViewState({
                    type: viewStateActionTypes.OpenAppModal,
                    payload: {
                        type: ModalTypes.MessageModal,
                        title: 'Success',
                        message: LoaderMessages.mint.SuccessMessage,
                    },
                });
                dispatchViewState({type: viewStateActionTypes.CloseAppLoader});
            });
    };

    const initContract = async (isMint, isTrans) => {

        dispatchViewState({
            type: viewStateActionTypes.OpenAppLoader,
            payload: isMint ?
                LoaderMessages.mint.WaitMessage
                : isTrans ? LoaderMessages.trans.CheckMetaMask
                    : LoaderMessages.login.CheckMetaMask
        });

        let transferValidation = false;

        const networkData = nftJson.networks[clientState.bc.networkId];

        if (networkData && ( clientState.isLoggedIn === true ||  clientState.isLoggedIn === 'true')) {
            const nftPar = new window.web3.eth.Contract(
                nftJson.abi,
                networkData.address
            );

            dispatchClientState({
                type: clientStateActionTypes.SetIsHaveNFT,
                payload: false,
            });

            let _baseURI = await nftPar.methods.baseURI().call();
            let _cost = await nftPar.methods.cost().call();
            let _maxSupply = await nftPar.methods.maxSupply().call();
            let _owner = await nftPar.methods.owner().call();
            let _paused = await nftPar.methods.isPaused().call();
            let _totalSupply = await nftPar.methods.totalSupply().call();
            let _walletOfOwner = await nftPar.methods.walletOfOwner(clientState.bc.account).call();

            Promise.all(
                [
                    _baseURI,
                    _cost,
                    _maxSupply,
                    _owner ,
                    _paused,
                    _totalSupply,
                    _walletOfOwner
                ])
                .then(() => {
                    setLocalContractConnection({ ...nftPar });
                    if (_walletOfOwner?.length > 0) {
                        dispatchClientState({
                            type: clientStateActionTypes.SetIsHaveNFT,
                            payload: _walletOfOwner?.length > 0,
                        });
                        dispatchRewardState({type: rewardStateActionTypes.UpdateAllRewards, payload: {
                                community: true,
                                early: true,
                                reserved: true,
                                nftFree: true,
                                reward: false,
                            }
                        });
                    }
                    else {
                        dispatchClientState({
                            type: clientStateActionTypes.SetIsHaveNFT,
                            payload: false,
                        });
                        dispatchRewardState({type: rewardStateActionTypes.ClearState});
                    }

                    if (isTrans) {
                        transferValidation = !!_walletOfOwner.find((item) => item.toString() === transAction.id.toString());
                    }
                    dispatchClientState({
                        type: clientStateActionTypes.SetSmart,
                        payload: {
                            address: networkData.address,
                            baseURI: _baseURI.toString(),
                            cost: _cost,
                            costPreview: `${window.web3.utils.fromWei(_cost, 'ether')} ETH`,
                            maxSupply: _maxSupply,
                            owner: _owner.toString(),
                            paused: _paused,
                            totalSupply: _totalSupply,
                            walletOfOwner: _walletOfOwner,
                        }
                    });
                    if (isMint) {
                        mintInit();
                    }
                    else {
                        if (isTrans) {
                            if (transferValidation) {
                                transactionInit();
                            }
                            else {
                                dispatchTransAction({
                                    type: processActionTypes.ClearAction
                                });
                                dispatchViewState({
                                    type: viewStateActionTypes.OpenAppModal,
                                    payload: {
                                        type: ModalTypes.MessageModal,
                                        title: 'Error',
                                        message: 'You are not the owner of this token',
                                    }
                                });
                                dispatchViewState({type: viewStateActionTypes.CloseAppLoader});
                            }
                        }
                        else {
                            dispatchViewState({type: viewStateActionTypes.CloseAppLoader});
                        }
                    }
                }).catch((error) => {
                    dispatchMintAction({
                        type: processActionTypes.AddAction,
                        payload: PROCESS_ACTION_TYPES.Ready,
                    });
                    dispatchViewState({type: viewStateActionTypes.CloseAppLoader});
                });
            /*
                if(_bc.doMint) {

                    console.log('wow')
                    nftWriteFnc('mint');
                }
            */
        }
        else {
            if (isMint) {
                dispatchMintAction({
                    type: processActionTypes.AddAction,
                    payload: PROCESS_ACTION_TYPES.Ready,
                });
            }
            else {
                dispatchViewState({type: viewStateActionTypes.CloseAppLoader});
            }
        }
    }


    const loginClient = async () => {
        if (window.ethereum) {
            dispatchViewState({type: viewStateActionTypes.OpenAppLoader, payload: 'Login Process'});

            window.web3 = new Web3(window.ethereum);

            const accounts = await window.web3.eth.getAccounts();
            const networkId = await window.web3.eth.net.getId();

            if (accounts.length>0 && networkId) {
                if (clientState.isLoggedIn !== true) {
                    dispatchClientState({
                        type: clientStateActionTypes.SetLoggedIn,
                        payload: true,
                    });
                }
                dispatchClientState({
                    type: clientStateActionTypes.SetEthAccount,
                    payload: {
                        account: accounts[0],
                        networkId: networkId,
                    }
                });
                dispatchViewState({type: viewStateActionTypes.CloseAppLoader});
            }
            else {
                dispatchClientState({
                    type: clientStateActionTypes.ClearLoggedIn
                });
                dispatchViewState({type: viewStateActionTypes.CloseAppLoader});
            }
        }
    };

    const handleAccountsChanged = () => {
        dispatchMintAction({
            type: processActionTypes.AddAction,
            payload: PROCESS_ACTION_TYPES.Ready,
        });
        dispatchTransAction({
            type: processActionTypes.AddAction,
            payload: PROCESS_ACTION_TYPES.Ready,
        });
        dispatchViewState({type: viewStateActionTypes.CloseAppModal});
        loginClient();
    }

    const handleChainChanged = (chainId) => {
        dispatchMintAction({
            type: processActionTypes.AddAction,
            payload: PROCESS_ACTION_TYPES.Ready,
        });
        dispatchTransAction({
            type: processActionTypes.AddAction,
            payload: PROCESS_ACTION_TYPES.Ready,
        });
        dispatchClientState({
            type: clientStateActionTypes.SetNetworkId,
            payload: chainId.toString().substr(2),
        });
        initContract(false, false);
    }

    useEffect(() => {
        if (clientState.isLoggedIn) {
            initContract(false, false);
        }
        else {
            dispatchClientState({ type: clientStateActionTypes.ClearSmart});
        }
    }, [clientState.loggerFn, clientState.bc.account, clientState.bc.networkId]);

    useEffect(() => {
        if (mintAction?.type === PROCESS_ACTION_TYPES.ReadyLoading) {
            initContract(true, false);
        }
        else {
            if (mintAction?.type === PROCESS_ACTION_TYPES.ConfirmLoading) {
                dispatchMintAction({
                    type: processActionTypes.AddAction,
                    payload: PROCESS_ACTION_TYPES.Ready,
                });
                initContract(false, false);
            }
        }
    }, [mintAction.type]);

    useEffect(() => {
        if (transAction?.type === PROCESS_ACTION_TYPES.ReadyLoading) {
            dispatchViewState({
                type: viewStateActionTypes.OpenAppModal,
                payload: {
                    type: ModalTypes.TransactionModal,
                }
            });
        } else{
            if (transAction?.type === PROCESS_ACTION_TYPES.ReadyToRequest) {
                dispatchViewState({
                    type: viewStateActionTypes.CloseAppModal
                });
                dispatchViewState({
                    type: viewStateActionTypes.OpenAppLoader,
                    payload: 'Please check metamask',
                });
                initContract(false, true);
            }
            else {
                if (transAction?.type === PROCESS_ACTION_TYPES.ConfirmLoading) {
                    dispatchTransAction({
                        type: processActionTypes.AddAction,
                        payload: PROCESS_ACTION_TYPES.Ready,
                    });
                    initContract(false, false);
                }
            }
        }
    } ,[transAction.type]);

    useEffect(() => {
        if (clientState.isLoggedIn !== true && clientState.isLoggedIn !== 'true') {
            dispatchClientState({ type: clientStateActionTypes.ClearId});
            dispatchClientState({ type: clientStateActionTypes.ClearSmart});
            dispatchClientState({ type: clientStateActionTypes.SetIsHaveNFT, payload: false});
            dispatchMintAction({type: processActionTypes.ClearAction});
            dispatchTransAction({type: processActionTypes.ClearAction});
            dispatchViewState({type: viewStateActionTypes.CloseAppLoader});
            dispatchRewardState({type: rewardStateActionTypes.ClearState});
        }
        else {
            initContract(false, false);
        }

        if (window.ethereum) {
            window.ethereum.on('chainChanged', handleChainChanged);
            window.ethereum.on('accountsChanged', handleAccountsChanged);
        }
        else {
            dispatchViewState({type: viewStateActionTypes.CloseAppLoader});
            dispatchViewState({type: viewStateActionTypes.CloseAppModal});
        }

        return () => {
            if (window.ethereum) {
                window.ethereum.removeListener('chainChanged', handleChainChanged);
                window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
            }
        }
    }, [clientState.isLoggedIn]);

    return null;
}
