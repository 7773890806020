import React, {useEffect, useState} from 'react'

export const Title = ({children, color, style}) => {

    const [localStyle, setLocalStyles] = useState({});

    useEffect(() => {
        if (style) {
            setLocalStyles(style);
        }
    }, [style]);

    return(
        <div style={{
            paddingTop:30,
            fontSize:30,
            fontWeight: 1000,
            color: color || 'white',
            textTransform: 'uppercase',
            ...localStyle,
            fontFamily: 'Montserrat, MontserratRegular'
        }}>
            {children}
        </div>
    );
}
