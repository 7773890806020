import React from 'react';
import { PrimarySection } from '../../shared/Section';
import { backgroundColors } from '../../../config/backgroundConfig';
import {TextBox} from "./components/TextBox";

import {Title} from "../../shared/Title";
import {CardItem} from "./components/CardItem";

import happySadIcon from '../../../assets/images/mint/happy-sad.svg';
import arrowIcon from '../../../assets/images/arrow-forward-black.svg';
import helperIcon from '../../../assets/images/mint/helper.svg';
import leftCardImage from '../../../assets/images/card/ezgif.com-gif-maker.gif';
import rightCardImage from '../../../assets/images/card/ezgif.com-gif-maker (1).gif';
import {MintSection} from "./MintSection";
import {TitleWIthIcon} from "./components/TitleWIthIcon";
import {TopIcon} from "./components/TopIcon";
import {TotalBlock} from "./components/TotalBlock";
import {BottomGrid} from "./components/BottomGrid";


export const JoinUs = () => {

    return(
        <>
            <PrimarySection
                backgroundColor="transparent"
                withoutContentBackground={true}
            >
                <Title>
                    <div
                        style={{
                            marginTop: '-20px',
                            marginBottom: '100px'
                        }}
                    >
                        JOIN THE WHITE LIST
                    </div>
                </Title>
            </PrimarySection>
            <PrimarySection
                backgroundColor="#20093a"
                contendBackgroundColor={backgroundColors.YellowDefault}
                paddingBottom='300px'
            >
                <TopIcon
                    src={happySadIcon}
                    alt="Happy Sad icon"
                />
                <TextBox>
                    mysterious Bored Toilet Paper Club (BTPC) NFTs are part of the entry process. The ownership of the BTPC is your pass to the Boogie Moons Club membership. You will need to Mint (buy) your BTPC NFT asset to join the club. The BTPC is limited to one per account. You can sell on secondary market your BTPC at any point should you decide to leave the organization.
                </TextBox>
                <TextBox>
                    1. Create an account by connecting your wallet (for ex. MetaMask)
                </TextBox>

                <MintSection />

                <TitleWIthIcon>
                    <div className="point-title">
                        3. Confirm transaction in your wallet.
                    </div>
                    <div className='icon-arrow'>
                        <img src={arrowIcon} alt="arrow-forward" />
                    </div>
                    <div className='icon-wrapper'>
                        <img src={helperIcon} alt="helper" />
                    </div>
                </TitleWIthIcon>

                <TextBox>
                    4. Recive your Toilet Paper Face.
                </TextBox>
            </PrimarySection>
            <PrimarySection
                backgroundColor='transparent'
                contendBackgroundColor={backgroundColors.PurpleDefault}
                zIndex={3}
            >
                <BottomGrid>
                    <div>
                        <CardItem
                            image={leftCardImage}
                            number="00234"
                        />
                        <TotalBlock style={{transform: 'scale(1.5)', marginTop: '2em'}} />
                    </div>
                    <div>
                        <TextBox
                            style={{
                                color: 'white',
                            }}
                        >
                            <h1 style={{textDecoration: 'underline'}}>And 5th</h1>
                            Recive  ONE MORE FREE
                            Toilet Paper Face as a Gift!
                        </TextBox>
                        <CardItem
                            image={rightCardImage}
                            number="08098"
                        />
                    </div>
                </BottomGrid>
            </PrimarySection>
        </>

    );
};
