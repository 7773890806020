import imgMucho from '../../../assets/images/card/126.png';
import imgRed from '../../../assets/images/card/177.png';
import imgMuse from '../../../assets/images/card/195.png';
import imgColazz from '../../../assets/images/card/1002.png';

export const imageGallery = [
    {
        img: imgMucho,
        name: '@Mucho',
        number: '0001,',
        description: 'HE\'S IS GROWING TO BE BIG',
    },
    {
        img: imgRed,
        name: '@Red07home',
        number: '0002,',
        description: 'BLOCKCHAIN DEVELOPER, PEANUT BUTTER LOVER',
    },
    {
        img: imgMuse,
        name: '@Loosaurus',
        number: '0003,',
        description: 'STRATEGIST, FINANCIAL ADVISER, PHOTOGRAPHER AND TANGO DANCER AT HEART',
    },
    {
        img: imgColazz,
        name: '@Colazzz',
        number: '0004,',
        description: 'ART DIRECTOR AND ARTIST, PLANT LOVER:BECAUSE THEY ARE SILENT ALL THE WAY',
    }
];
