import styled from "@emotion/styled";

export const Divider = styled.div`
  max-width: 30%;
  width: 100%;
  height: 1em;
  overflow: hidden;
  
  @media (min-width: 700px) {
    height: 5em;
  }
`
