import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {GlobalStateContext} from "../../../../store/GlobalState";
import {PrimaryTextField} from "../../../shared/inputs";
import {DefaultSubmitButton} from "../../../shared/buttons";
import {EmailFormWrapper} from "./EmailFormWrapper";

export const EmailInput = () => {

    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const {clientState} = useContext(GlobalStateContext);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const getEmailApiCall = async (email) => {
        if (email) {
            axios.get(`${process.env.REACT_APP_BASE_API}`, {params: {
                    action: "set_email",
                    account: clientState.bc.account,
                    email: email
                },
                cancelToken: source.token
            }).then((response) => {
                if (response.data.status_code === '2' || response.data.status_code === 2) {
                    setSuccessMessage(response.data.msg || 'You have successfully subscribed.');
                } else {
                    setSuccessMessage(response.data.msg || 'something went wrong');
                }
            }).catch((error) => {
                setError(error.message);
            });
        }
    }

    const handleSubmitForm = (event) => {
        event.preventDefault();
        getEmailApiCall(event.target.email.value);
    };

    useEffect(() => {
        return() => {
            source.cancel();
        };
    },[]);

    return(
        <EmailFormWrapper className='email-input-wrapper'>
            {
                successMessage === '' &&
                <form onSubmit={handleSubmitForm}>
                    <PrimaryTextField
                        title="please insert your email"
                        placeholder="Enter Your e-mail"
                        variant="outlined"
                        size='small'
                        type='email'
                        name='email'
                        error={!!error}
                    />
                    <DefaultSubmitButton
                        type="submit"
                        className="submit-button"
                    >submit</DefaultSubmitButton>
                </form>
            }
            {
                successMessage &&
                    <div style={{
                        fontSize: 'smaller',
                        marginTop: '1em',
                    }}>
                        {successMessage}
                    </div>
            }
        </EmailFormWrapper>
    );
};
