import React, {useContext, useEffect} from 'react';
import {
    TopLogotype,
    Button,
    ReadDocumentation,
    HelpDesk,
    JoinUs,
    MyRoom,
    RewardSection,
    WeAreNot,
    UpcomingCollections,
} from '../../components/sections';
import { SectionsWrapper } from '../../components/shared/Section';
import {clientStateActionTypes, GlobalStateContext} from "../../store/GlobalState";
import {PROCESS_ACTION_TYPES} from "../../config/actionConfig";
import {ChangeWallet} from "../../components/sections/ChangeWallet";
import {availableNetworkId} from "../../config/websiteConfig";
import {useParams} from "react-router-dom";

export const HomePage = () => {

    const {clientState, mintAction, transAction, dispatchClientState} = useContext(GlobalStateContext);
    const {id} = useParams();

    useEffect(() => {
        if (id) {
            dispatchClientState({
                type: clientStateActionTypes.SetId,
                payload: id
            });
        }
    },[]);

    return(
        <SectionsWrapper>
            <TopLogotype />
            <Button />
            <ReadDocumentation />
            {
                clientState.isLoggedIn &&
                clientState.bc.networkId?.toString() !== availableNetworkId &&
                <ChangeWallet />
            }
            {
                (
                    clientState.isHaveNFT ||
                    transAction.type !== PROCESS_ACTION_TYPES.Ready
                ) && mintAction.type === PROCESS_ACTION_TYPES.Ready &&
                clientState.bc.networkId?.toString() === availableNetworkId &&
                clientState.isLoggedIn &&
                <MyRoom />
            }
            {
                ((
                    clientState.isLoggedIn &&
                    !clientState.isHaveNFT &&
                    transAction.type === PROCESS_ACTION_TYPES.Ready &&
                    clientState.bc.networkId?.toString() === availableNetworkId
                ) || (
                    !clientState.isLoggedIn
                )) &&

                <JoinUs />
            }
            <RewardSection />
            <WeAreNot />
            <UpcomingCollections />
            <HelpDesk />
        </SectionsWrapper>
    );
}
