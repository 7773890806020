import React from 'react';
import { PrimarySection } from '../../shared/Section';
import { backgroundColors } from '../../../config/backgroundConfig';
import {Title} from "../../shared/Title";


export const ChangeWallet = () => {

    return(
        <PrimarySection
            backgroundColor='transparent'
            contendBackgroundColor={backgroundColors.PurpleDefault}
            zIndex={3}
        >
            <Title>
                Please Connect to Ethereum Mainnet!
            </Title>
        </PrimarySection>

    );
};
