import {smartContractConfig} from "../../../../config/websiteConfig";
import {SmartContactWrapper} from "./SmartContactWrapper";


export const SmartContractButton = () => {

    return(
        <SmartContactWrapper>
            <a href={smartContractConfig.url} target="_blank" rel="noopener noreferrer">
                <div className='title'>
                    {
                        smartContractConfig.name
                    }
                </div>
                <div className='description'>
                    {
                        smartContractConfig.description
                    }
                </div>
            </a>
        </SmartContactWrapper>
    );
};
