import styled from "@emotion/styled";

export const ButtonSmall = styled.button`
  padding: 0.7em 1.2em;
  margin: 0;
  color: #07182f;
  border: none;
  outline: none;
  font-size: 1em;
  border-radius: 22px;
  text-transform: uppercase;
  background-color: #1A74BB;
  cursor: pointer;
  font-weight: bold;
`;
