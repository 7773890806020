import styled from "@emotion/styled";

export const CollectionContainer = styled.div`
  width: 100%;
  margin: 1em 0;

  &> img {
    width: 100%;
  }
  
  &> a img {
    width: 100%;
  }
`
