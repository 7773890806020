import styled from '@emotion/styled';
import { uiConfig } from '../../../../config/uiConfig';

export const Container = styled('section')(({ theme, backgroundColor }) => ({
    width: '100%',
    color: backgroundColor || theme.palette.primary.connectButtonColor,
    '&> .section-wrapper': {
        position: 'relative',
        maxWidth: uiConfig.maxWidth,
        margin: '0 auto',
        textAlign: 'center',
        height: '100%',

        '&> .section-content': {
            position: 'relative',
            zIndex: 50,
            padding: '0',
        }
    },
    '&> .section-wrapper-with-background': {
        position: 'relative',
        maxWidth: uiConfig.maxWidth,
        margin: '0 auto',
        textAlign: 'center',
        height: '100%',

        '&> .section-content': {
            position: 'relative',
            zIndex: 50,
            padding: '2em 0',
        }
    },
}));
