import React from "react";
import {ButtonSmall} from "./components/ButtonSmall";

export const DefaultSubmitButton = (props) => {

    const {onClick, children} = props;

    return(
        <ButtonSmall
            { ...props}
            onClick={() => {
                if (onClick) {
                    onClick();
                }
            }}
        >{children}</ButtonSmall>
    );
}
