import { createTheme } from '@mui/material/styles';

export const primaryTheme = createTheme({
    palette: {
        primary: {
            main: "#000000",
            backgroundHeader: "#000000",
            backgroundBody: "#20093a",
            textPrimary: "#FFFFFF",
            connectButtonColor: "#000",
            connectButtonBackground: '#eee',
        },
        secondary: {
            main: "#ff27a0",
        },
    },
});
