import styled from "@emotion/styled";

export const MintContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  direction: rtl;
  margin-top: -4em;
  padding-bottom: 3em;
  
  &> .mint-block {
    display: flex;
    flex-direction: column;
    gap: 1em;
    direction: ltr;
  }
  
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    margin-top: 0;
  }
`;
