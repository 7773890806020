import React from 'react';
import { Wrapper } from './components/Wrapper';

export const SectionsWrapper = ({children}) => {

    return(
        <Wrapper>
            {children}
        </Wrapper>
    );
}
