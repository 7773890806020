const localState = class {

    static getLoggedIn = () => {
        return localStorage.getItem('loggedIn') === 'true' || localStorage.getItem('loggedIn') === true;
    }

    static setLoggedIn = (loggedIn) => {
        return localStorage.setItem('loggedIn', loggedIn);
    }

    static getLocalId = () => {
        return localStorage.getItem('localID') || '';
    };

    static setLocalId = (id) => {
        localStorage.setItem('localID', id);
    }

    static removeLocalId = () => {
        localStorage.removeItem('localID');
    }

    static setIsReadDescription = (isRead) => {
        localStorage.setItem('isRead', isRead);
    }

    static getIsReadDescription = () => {
        return localStorage.getItem('isRead') === 'true' || localStorage.getItem('isRead') === true
    }

    static setIsFirstVisit = (isRead) => {
        localStorage.setItem('isFirstVisit', isRead);
    }

    static getIsFirstVisit = () => {
        return localStorage.getItem('isFirstVisit') === 'true' || localStorage.getItem('isFirstVisit') === true
    }
};

export default localState;
