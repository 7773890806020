import React from 'react';

import { Container } from './components/Container';
import {BackgroundPrimary} from "./components/BackgroundPrimary";

export const PrimarySection = (
    {
        withoutContentBackground = false,
        contendBackgroundColor,
        contentBackgroundItem,
        backgroundColor,
        zIndex = 1,
        marginTop = '0',
        marginBottom = '0',
        paddingBottom = '0',
        children
    }
) => {

    return(
        <Container
            style={{
                backgroundColor: backgroundColor,
                position: 'relative',
                zIndex: zIndex,
                marginTop: marginTop,
                marginBottom: marginBottom,
            }}
        >
            <div className={`${ withoutContentBackground ? "section-wrapper" : "section-wrapper-with-background" }`}>
                <div className="section-content" style={{paddingBottom: paddingBottom}}>
                    {children}
                </div>
                {
                    withoutContentBackground===false &&
                    <BackgroundPrimary backgroundColor={contendBackgroundColor} backgroundItem={contentBackgroundItem} />
                }
            </div>
        </Container>
    );
}
