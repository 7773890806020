import React from 'react';
import styled from '@emotion/styled';

const CardBackgroundTop = () => {

    return(
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 400 60"
        >
            <polygon fill="#FFF" points="321.4,3.84 162.65,11.47 147.31,3.76 0.32,4.25 0.32,60 400.32,60 400.32,6.35 "/>
        </svg>
    )
}

const CardBackgroundBottom = () => {

    return(
        <div style={{marginTop:'-1px',}}>
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 400 60"
            >
                <polygon fill="#FFF" points="32.3,34.45 356.93,28.04 400.32,28.04 400.32,0 0.32,0 0.32,28.04 "/>
            </svg>
        </div>
    )
}

const CardWrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 auto;
`;

const CardContent = styled.div`
  width: 100%;
  margin-top: -5px;
  background-color: white;
  text-align: left;
`;

const ImageWrapper = styled.div`
  width: 80%;
  margin: 1em 10%;
  
  &> img {
    width: 100%;
    height: auto;
  }
`;

const CardTitle = styled.div`
  text-align: center;
  width: 100%;
  color: #000;
`;

export const CardItem = ({image, number}) => {

    return(
        <CardWrapper>
            <CardBackgroundTop />
            <CardContent>
                <ImageWrapper>
                    {
                        image &&
                        <img src={image} alt="card item" />
                    }
                </ImageWrapper>
                    <CardTitle>
                        #{number}
                    </CardTitle>
            </CardContent>
            <CardBackgroundBottom />
        </CardWrapper>
    );
}
