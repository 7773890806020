import {useContext, useEffect} from "react";
import axios from "axios";
import {GlobalStateContext} from "../store/GlobalState";

export const UseCallReferral = () => {
    const {clientState} = useContext(GlobalStateContext);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const callReferral = async (transHash) => {
        await axios.get(`${process.env.REACT_APP_BASE_API}`, {params: {
                action: "set_referral",
                ref_token_id: (!clientState.localId) ? 'no-referral' :  clientState.localId,
                account: clientState.bc.account,
                eth: clientState.smart.cost,
                trans_hash: transHash,
                total_supply: clientState.smart.totalSupply,
            },
            cancelToken: source.token
        })
    };

    useEffect(() => {
        return() => {
            source.cancel();
        };
    },[]);

    return {
        callReferral
    }
}
