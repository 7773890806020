import styled from "@emotion/styled";
import backgroundImage from '../../../../assets/images/whoWeAre/description-background.svg';

export const Description = styled.div`
  font-size: 0.7em;
  text-align: left;
  bottom: 3em;
  z-index: 12;
  /*
  background-image: url("${backgroundImage}");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  */
  background-color: white;
  width: auto;
  padding: 10px;
  overflow: hidden;
`;
