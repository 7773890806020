import React, {useContext} from "react";
import {GlobalStateContext} from "../../../../store/GlobalState";
import {InputStyledBlock} from "./InputStyledBlock";

export const PriceBlock = () => {

    const {clientState, preloadState} = useContext(GlobalStateContext);

    return(
            <div>
                <InputStyledBlock>
                {
                    clientState.smart?.costPreview &&
                    <>{clientState.smart?.costPreview}</>
                }
                {
                    !clientState.smart?.costPreview && preloadState.cost &&
                    <>{Number((preloadState.cost)/(Math.pow(10, 18))) } ETH</>
                }
                </InputStyledBlock>
            </div>
    );
}
