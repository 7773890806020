import styled from "@emotion/styled";

export const EmailFormWrapper = styled.div`
  margin-top: 10px;
  vertical-align: center;
  
  &> form .submit-button {
    margin-left: 10px;
    @media(max-width: 470px) {
      display: block;
      margin-left: 0;
      margin-top: 10px;
    }
  }
`
