import React from 'react';
import {PrimarySubmitButton} from "../../../shared/buttons";

export const ConnectButton = ({children, onClick}) => {
    return(
        <PrimarySubmitButton
            onClick={()=>{
                if(onClick) {
                    onClick();
                }
            }}
        >
            {
                children
            }
        </PrimarySubmitButton>
    );
};
