import React, {useContext, useState} from 'react';

import { Container } from './components/Container';
import { ConnectButton } from './components/ConnectButton';

import logotypeImage from '../../../assets/images/header-logotype.svg';
import {GlobalStateContext} from "../../../store/GlobalState";
import {UseEthConnect} from "../../../hoox/UseEthConnect";
import {availableNetworkId} from "../../../config/websiteConfig";
import {DeviceTypes} from "../../../config/uiConfig";

export const PrimaryHeader = () => {

    const { clientState, viewState } = useContext(GlobalStateContext);
    const [error, setError] =  useState('');
    const {handleConnect} = UseEthConnect();

    return(
        <Container>
            <span className="left-block" />
            <img className='logotype' src={logotypeImage} alt='logotype' />
            <div className='connect-wrapper'>
                {
                    (viewState.loaders.appPreloading === false && clientState.isLoggedIn === false) &&
                    <div style={{textAlign: 'right'}}>
                        {
                            viewState.deviceType === DeviceTypes.Desktop &&
                            <>
                                <ConnectButton
                                    onClick={
                                        () => {
                                            if (window.ethereum) {
                                                handleConnect();
                                            } else {
                                                setError('Please install metamask.');
                                            }
                                        }
                                    }
                                >CONNECT</ConnectButton>

                                {
                                    error &&
                                    <div style={{color: '#FF0000',fontSize: '0.6', padding: '0.5em'}}>
                                        {error}
                                    </div>
                                }
                            </>

                        }
                        {
                            viewState.deviceType !== DeviceTypes.Desktop &&
                            <a href={`https://metamask.app.link/dapp/ps.bmlc.io/${clientState.localId ? clientState.localId : ''}`}  rel="noopener noreferrer" >
                                <ConnectButton >
                                    CONNECT
                                </ConnectButton>
                            </a>
                        }
                    </div>
                }
                <div>
                    {
                        (
                            clientState.isLoggedIn &&
                            clientState.bc.account
                        ) &&
                        <p>{`Account: ${clientState.bc.account.toString().substr(0, 5)}...${clientState.bc.account.toString().substr(38, 4)}`}</p>
                    }
                    {
                        (
                            !viewState.loaders.appPreloading &&
                            clientState.isLoggedIn &&
                            clientState.bc.networkId &&
                            clientState.bc.networkId?.toString() !== availableNetworkId
                        ) &&
                        <p className="error-message">Please Connect to Ethereum Mainnet!</p>
                    }
                </div>

            </div>
        </Container>
    );
};
