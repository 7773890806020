import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { HomePage, ErrorPage, ClearId } from './pages';

export const AppRoutes = () => {

    return(
        <Routes>
            <Route path="/" element={<HomePage />}/>
            <Route path="/clear" element={<ClearId />}/>
            <Route path="/:id" element={<HomePage />}/>
            <Route path="*" element={<ErrorPage />}/>
        </Routes>
    );
};
