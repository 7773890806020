import React, {useContext} from "react";
import {Button} from "@mui/material";
import {backgroundColors} from "../../../config/backgroundConfig";
import {Container} from "./components/Container";
import {GlobalStateContext, viewStateActionTypes} from "../../../store/GlobalState";

export const MessageModal = () => {

    const {viewState, dispatchViewState} = useContext(GlobalStateContext);

    return(
        <Container>
            <h1><span style={{color: backgroundColors.PinkLight}}>{viewState.modal.title}</span></h1>
            <p>{viewState.modal.message}</p>
            <div style={{textAlign: 'right', marginTop: '2em',}}>
                <Button
                    variant="filled"
                    onClick={() => {
                        dispatchViewState({
                            type: viewStateActionTypes.CloseAppModal,
                        });
                    }}
                    style={{backgroundColor: backgroundColors.PinkLight}}
                >Ok</Button>
            </div>

        </Container>
    );
}
