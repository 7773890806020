export const clientStateActionTypes = {
    SetId: 'SetId',
    ClearId: 'ClearId',
    SetLoggedIn: 'SetLoggedIn',
    ClearLoggedIn: 'ClearLoggedIn',
    SetIsHaveNFT: 'SetIsHaveNFT',
    SetAccount: 'SetAccount',
    SetNetworkId: 'SetNetworkId',
    SetEthAccount: "SetEthAccount",
    SetSmart: 'SetSmart',
    ClearSmart: "ClearSmart",
}

export const viewStateActionTypes = {
    SetDeviceType: 'SetDeviceType',
    SetDeviceSize: 'SetDeviceSize',
    SetIsFirstVisit: 'SetIsFirstVisit',
    SetIsReadDocumentation: 'SetIsReadDocumentation',
    OpenAppLoader: 'OpenAppLoader',
    CloseAppLoader: 'CloseAppLoader',
    SetAppPreloading: 'SetAppPreloading',
    ClearAppPreloader: 'ClearAppPreloader',
    OpenAppModal: 'OpenAppModal',
    CloseAppModal: 'CloseAppModal',
}

export const rewardStateActionTypes = {
    SetNFTFree: 'SetNFTFree',
    SetReserved: 'SetReserved',
    SetEarly: 'SetEarly',
    SetCommunity: 'SetCommunity',
    SetReward: 'SetReward',
    UpdateAllRewards: 'UpdateAllRewards',
    ClearState: 'ClearState',
}

export const processActionTypes = {
    AddAction: 'AddAction',
    AddMessage: 'AddMessage',
    SetId: 'SetId',
    SetTransferId: 'SetTransferId',
    ClearAction: 'ClearAction',
    ClearMessage: 'ClearMessage',
    ClearId: 'ClearId',
    ClearTransferId: 'ClearTransferId',
}

export const preloadActionTypes = {
    setData: 'setData',
    clearData: 'clearData',
}
