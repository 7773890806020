import React, {useContext} from "react";
import {backgroundColors} from "../../../config/backgroundConfig";
import {TextBox} from "./components/TextBox";
import tritoilIcon from "../../../assets/images/mint/tritoil.svg";
import {PrimarySubmitButton} from "../../shared/buttons";
import {PrimarySection} from "../../shared/Section";
import {GlobalStateContext} from "../../../store/GlobalState";
import {PROCESS_ACTION_TYPES} from "../../../config/actionConfig";
import {processActionTypes} from "../../../store/GlobalState/actions";
import {UseEthConnect} from "../../../hoox/UseEthConnect";
import {PriceBlock} from "./components/PriceBlock";
import {TotalBlock} from "./components/TotalBlock";
import {MintContentContainer} from "./components/MintContentContainer";
import arrowIcon from "../../../assets/images/arrow-forward-black.svg";
import {DeviceTypes} from "../../../config/uiConfig";
import {Hint} from "./components/Hint";
import {whitePaperURL} from "../../../config/websiteConfig";

export const MintSection = () => {

    const { clientState, viewState, mintAction, dispatchMintAction } = useContext(GlobalStateContext);

    const { handleConnect } = UseEthConnect();

    const handleMeetButton = () => {
        if (mintAction?.type === PROCESS_ACTION_TYPES.Ready) {
            dispatchMintAction({
                type: processActionTypes.AddAction,
                payload: PROCESS_ACTION_TYPES.ReadyLoading,
            });
        }
    }

    return (
        <PrimarySection
            backgroundColor="transparent"
            contendBackgroundColor={backgroundColors.PinkDefault}
        >
            <TextBox style={{paddingTop: '1em'}}>
                2. Mint your Boogie's Club Pass (max. one per wallet) with the actual price at that moment.
            </TextBox>
            {
                viewState.deviceSize.width > 600 &&
                <div className='icon-arrow'>
                    <img src={arrowIcon} style={{
                        maxWidth: '130px',
                        transform: 'rotate(30deg)',
                        marginBottom: '2em',
                    }} alt="arrow-forward" />
                </div>
            }
            <MintContentContainer>
                <div className="mint-block">
                    <PriceBlock />
                    <div>
                        {
                            clientState.isLoggedIn &&
                            !clientState.isHaveNFT &&
                            <>
                                <PrimarySubmitButton
                                    style={{
                                        color: 'black',
                                    }}
                                    onClick={() => {
                                        handleMeetButton()
                                    }}
                                >MINT</PrimarySubmitButton>
                                <Hint>
                                    By pressing this button you confirm that you read and agree with our <a href={whitePaperURL} target='_blank' rel="noopener noreferrer">White Paper</a>
                                </Hint>
                            </>
                        }
                        {
                            !clientState.isLoggedIn &&
                            <>
                                {
                                    viewState.deviceType === DeviceTypes.Desktop &&
                                    <PrimarySubmitButton
                                        onClick={() => {
                                            handleConnect()
                                        }}
                                    >CONNECT</PrimarySubmitButton>
                                }
                                {
                                    viewState.deviceType !== DeviceTypes.Desktop &&
                                    <a href={`https://metamask.app.link/dapp/ps.bmlc.io/${clientState.localId ? clientState.localId : ''}`}
                                       rel="noopener noreferrer">
                                        <PrimarySubmitButton>CONNECT</PrimarySubmitButton>
                                    </a>
                                }
                            </>
                        }
                    </div>
                    <TotalBlock />
                </div>
                <img src={tritoilIcon} alt="tritoil icon" />
            </MintContentContainer>
        </PrimarySection>
    );
}
