import styled from '@emotion/styled';

export const BackgroundWrapper = styled.div`
  position: absolute;
  width: 100%;
  min-height: 60px;
  height: 100%;
  z-index: 48;
  top: 0;
  
  &> .background-blocks {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    
    &> .background-top {
      width: 100%;
      height: auto;
      overflow: hidden;
    }
    &> .background-middle {
      margin-top: -5px;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    &> .background-bottom {
      width: 100%;
      margin-top: -5px;
      height: auto;
      overflow: hidden;
      bottom: 0;
    }
  }
`;
