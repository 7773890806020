import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import {
    BaseLayout, PrimaryHeader, PrimaryContent, PrimaryFooter, ModalWrapper, LoaderWrapper,
} from './components/layout';
import { ThemeProvider } from '@mui/material/styles';
import { primaryTheme } from './components/layout/theme';
import { AppRoutes } from './AppRoutes';
import {
    GlobalStateProvider
} from './store/GlobalState';
import {AppPreloader} from "./hoox/AppPreloader";
import {EtheriumInitial} from "./hoox/EtheriumInitial";

const App = () => {
  return(
      <ThemeProvider theme={primaryTheme}>
          <Router>
              <GlobalStateProvider>
                  <BaseLayout>
                      <AppPreloader />
                      <EtheriumInitial />
                      <ModalWrapper />
                      <LoaderWrapper />
                      <PrimaryHeader />
                      <PrimaryContent>
                          <AppRoutes />
                      </PrimaryContent>
                      <PrimaryFooter />
                  </BaseLayout>
              </GlobalStateProvider>
          </Router>
      </ThemeProvider>
  );
};

export default App;
