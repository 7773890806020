import React from 'react';
import {backgroundColors} from "../../../config/backgroundConfig";
import {Title} from "../../shared/Title";
import {PrimarySection} from "../../shared/Section";
import {CardContainer} from "./components/CardContainer";
import {CardItem} from "./components/CardItem";
import {imageGallery} from "./constants";

export const WeAreNot = () => {

    return(
        <PrimarySection
            contentBackgroundItem={backgroundColors.gradientPurple}
        >
            <Title>
                we are <span style={{color: '#000'}}>NOT</span> <br />
                just another toilet papers!
            </Title>
            <CardContainer>
                {
                    imageGallery.map((item, key) => (
                        <CardItem
                            key={`${item.id}-${key}`}
                            image={item.img}
                            name={item.name}
                            number={item.number}
                            decription={item.description}
                        />
                    ))
                }
            </CardContainer>
        </PrimarySection>
    );
}
