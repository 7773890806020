import styled from '@emotion/styled';
import {uiConfig} from "../../../../config/uiConfig";

export const BottomContainer = styled.div`
  display: grid;
  max-width: ${uiConfig.maxWidth};
  margin: 0 auto;
  grid-template-columns: auto 1fr;
  grid-gap: 1em;
  padding: 1em;
  align-items: end;
  width: 100%;
  direction: rtl;
  
  &> .social-icons-wrapper {
    text-align: right;
    direction: ltr;
    &> .social-icons {
      grid-gap: 20px;
      
      &> div {
        &> a:hover {
          filter: brightness(0) invert(1);
        }
      }
    }
  }
  
  &> .copyright {
    text-align: left;
    line-height: 2em;
    direction: ltr;
  }
  
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    &> .social-icons-wrapper {
      text-align: center;
      &> .social-icons {
        justify-content: center;
      }
    }
    &> .copyright {
      text-align: center;
    }
  }
`
