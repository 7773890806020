import React, {useContext} from 'react';
import {Checkbox} from "@mui/material";
import {backgroundColors} from "../../../../config/backgroundConfig";
import {GlobalStateContext} from "../../../../store/GlobalState";
import {EmailInput} from "./EmailInput";
import {SocialBlock} from "../../../shared/SocialBlock";
import {SocialBlockWrapper} from "./SocialBlockWrapper";

export const DescriptionBlock = ({item}) => {
    const {id, title, description, checkBoxName, isHaveEmailInput, isHaveSocialIcons} = item;

    const {clientState, rewardState} = useContext(GlobalStateContext);

    return (
        <div
            style={{
                textAlign: 'left',
                padding: '0.5em 1em',
                color: 'white',
                textTransform: "uppercase",
                display: 'grid',
                gridTemplateColumns: '70px auto'
            }}
        >
            <h1 style={{color: backgroundColors.PurpleDefault}}>
                <Checkbox
                    style={{
                        marginTop: '-5px',
                        opacity: clientState.isLoggedIn ? '1' : '0',
                    }}
                    name={checkBoxName}
                    checked={rewardState[checkBoxName]}
                />
                <span>{id}.</span>
            </h1>
            <div>
                <h1 style={{marginBottom: 0}}> {title}</h1>
                <div style={{color: backgroundColors.PurpleDefault, fontFamily: 'Montserrat', whiteSpace: 'pre-line'}}>{description}</div>
                {
                    isHaveEmailInput && clientState?.smart?.walletOfOwner?.length > 0 &&
                    <EmailInput />
                }
                {
                    isHaveSocialIcons &&
                    <SocialBlockWrapper>
                        <SocialBlock iconWidth={30} iconHeight={30}/>
                    </SocialBlockWrapper>
                }
            </div>
        </div>
    );
}
