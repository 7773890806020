import primaryPurpleGradient from '../assets/images/backgrounds/primary-purple-gradient.svg';

export const BackgroundGradientPurple = () => (
    <linearGradient
        id="gradient-purple"
        gradientUnits="userSpaceOnUse"
        x1="-100%"
        y1="-10%"
        x2="300%"
        y2="300%"
    >
        <stop
            offset="0"
            style={{stopColor:"#08A8DD"}}
        />
        <stop
            offset="0.2"
            style={{stopColor:"#35368F"}}
        />
        <stop
            offset="0.4112"
            style={{stopColor:"#E71788"}}
        />
        <stop
            offset="0.452"
            style={{stopColor:"#E82488"}}
        />
        <stop
            offset="0.5014"
            style={{stopColor:"#E83D88"}}
        />
        <stop
            offset="0.5554"
            style={{stopColor:"#EA5987"}}
        />
        <stop
            offset="0.6126"
            style={{stopColor:"#EC7684"}}
        />
        <stop
            offset="0.6723"
            style={{stopColor:"#EF957B"}}
        />
        <stop
            offset="0.7342"
            style={{stopColor:"#F4BA67"}}
        />
        <stop
            offset="0.7968"
            style={{stopColor:"#FBE52D"}}
        />
        <stop
            offset="0.8"
            style={{stopColor:"#FBE727"}}
        />
        <stop
            offset="1"
            style={{stopColor:"#049D54"}}
        />
    </linearGradient>
);

export const backgroundColors = {
        YellowDefault: '#ffc50a',
        PurpleDefault: '#20093a',
        PurpleLight: '#481581',
        PinkLight: '#ec86ff',
        PinkDefault: '#eb008b',
        BlueDefault: '#00adee',
        gradientPurple: {
            id: 'gradient-purple',
            svg: primaryPurpleGradient,
            svgGradient: BackgroundGradientPurple,
        },
}
