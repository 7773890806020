import {useEffect} from "react";
import {useLocation} from "react-router-dom";

import isLocalHost from "../../utils/isLocalHost";
import TagManager from "react-gtm-module";

export const RouterListener = () => {

    let routeLocation = useLocation();

    useEffect(() => {
        if (!isLocalHost()) {
            TagManager.dataLayer({
                dataLayer: {
                    page: routeLocation.pathname
                },
                dataLayerName: 'PageDataLayer'
            });
        }
    },[routeLocation]);

    return null;
}
