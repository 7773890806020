import React from 'react';
import { PrimarySection } from '../../shared/Section';
import homeSVG from '../../../assets/images/home-toilet-big-icon.svg';
import homeBottomSVG from '../../../assets/images/home-title-bottom-icon.svg';
import {Icon} from "./components/Icon";
import {backgroundColors} from "../../../config/backgroundConfig";

export const TopLogotype = () => {

    return(
        <PrimarySection
            backgroundColor={backgroundColors.PurpleDefault}
            withoutContentBackground={true}
        >
            <Icon src={homeSVG} alt='home-svg' />
            <Icon src={homeBottomSVG} alt='home-svg' />
        </PrimarySection>
    );
};
