import React, {useContext, useEffect, useRef, useState} from 'react';
import styled from '@emotion/styled';
import {GlobalStateContext} from "../../../../store/GlobalState";
import {processActionTypes} from "../../../../store/GlobalState/actions";
import {PROCESS_ACTION_TYPES} from "../../../../config/actionConfig";
import {SecondarySubmitButton} from "../../../shared/buttons";
import cardBlankBackground from '../../../../assets/images/card/card-preloader.png'

const CardBackgroundTop = () => {

    return(
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 400 60"
        >
            <polygon fill="#FFF" points="321.4,3.84 162.65,11.47 147.31,3.76 0.32,4.25 0.32,60 400.32,60 400.32,6.35 "/>
        </svg>
    )
}

const CardBackgroundBottom = () => {

    return(
        <div style={{marginTop: '-1px',}}>
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 400 60"
            >
                <polygon fill="#FFF" points="32.3,34.45 356.93,28.04 400.32,28.04 400.32,0 0.32,0 0.32,28.04 "/>
            </svg>
        </div>
    )
}

const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const CardContent = styled.div`
  width: 100%;
  margin-top: -5px;
  background-color: white;
  text-align: left;
`;

const ImageWrapper = styled.div`
  width: 80%;
  margin: 1em 10%;
  background-image: url("${cardBlankBackground}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90% 90%;
  
  &> img {
    width: 100%;
    height: auto;
  }
`;

const CardDescription = styled.div`
  margin: 0.5em;
`;

const CardTitle = styled.span`
  text-align: center;
  font-size: 1.5em;
  font-weight: bolder;
`;

const ButtonWrapper = styled.span`
  margin-bottom: 1em;
`;

export const CardItem = ({image, sellPath, number}) => {

    const imgRef = useRef();
    const [localImageHeight, setLocalImageHeight] = useState(50);
    const { viewState } = useContext(GlobalStateContext);

    const {
        transAction, dispatchTransAction,
    } = useContext(GlobalStateContext);

    const handleTrans = () => {
        dispatchTransAction({
            type: processActionTypes.SetId,
            payload: number,
        });
        dispatchTransAction({
            type: processActionTypes.AddAction,
            payload: PROCESS_ACTION_TYPES.ReadyLoading,
        });
    };

    useEffect(() => {
        if (imgRef?.current?.offsetWidth) {
            setLocalImageHeight(imgRef?.current?.offsetWidth);
        }

    },[viewState.deviceSize.width, viewState.deviceSize.height]);

    return(
      <CardWrapper>
          <CardBackgroundTop />
          <CardContent>
              <ImageWrapper ref={imgRef} style={{height: `${localImageHeight}px`}}>
                  {
                      image &&
                      <img src={`${image}`} alt="card item" />
                  }
              </ImageWrapper>
              <CardDescription>
                  <CardTitle>
                      <div>#{number}</div>
                  </CardTitle>
              </CardDescription>
          </CardContent>
          <CardBackgroundBottom />
          <ButtonWrapper>
              <a href={`${sellPath}`} target="_blank" rel="noopener noreferrer" >
                  <SecondarySubmitButton>
                      SELL
                  </SecondarySubmitButton>
              </a>
              <SecondarySubmitButton
                  disabled={transAction.type !== PROCESS_ACTION_TYPES.Ready}
                  onClick={
                      () => {handleTrans()}
                    }
                  style={{
                      marginLeft: '1em',
                      marginBottom: '1em',
                  }}
              >
                  transfer
              </SecondarySubmitButton>
          </ButtonWrapper>
      </CardWrapper>
    );
}
