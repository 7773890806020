import styled from "@emotion/styled";
import {TextField} from "@mui/material";

export const StyledTextField = styled(TextField)`
  &> div {
    border-radius: 22px;
  }
  &> div input {
    background-color: white;
    border-radius: 22px;
  }
`


export const PrimaryTextField = (props) => {
    return(
        <StyledTextField {...props} />
    )
}
