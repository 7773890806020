import React from "react";
import {backgroundColors} from "../../../config/backgroundConfig";
import {PrimarySection} from "../../shared/Section";

export const DividerSection = (
    {
        marginTop = "0",
        minHeight = '6em',
        zIndex=0,
    }
) => {


    return(
        <PrimarySection
            backgroundColor='transparent'
            contendBackgroundColor={backgroundColors.PurpleDefault}
            zIndex={zIndex}
            marginTop={marginTop}
        >
            <div
                style={{
                    minHeight: minHeight,
                }}
            />
        </PrimarySection>
    );
};
