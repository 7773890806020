export const PROCESS_ACTION_TYPES = {
    Ready: 'Ready',
    ReadyLoading: 'ReadyLoading',
    ReadyToRequest: 'ReadyToRequest',
    Request: 'Request',
    RequestLoading: 'RequestLoading',
    ReadyToConfirm: 'ReadyToConfirm',
    Confirm: 'Confirm',
    ConfirmLoading: 'ConfirmLoading',
    PreConfirmation: 'PreConfirmation',
    ConfirmConfirmation: 'ConfirmConfirmation',
    ConfirmError: 'ConfirmError',
    Reject: 'Reject',
    RejectLoading: 'RejectLoading',
};

export const LoaderMessages = {
    login: {
      CheckMetaMask: 'Please check Your MetaMask.',
    },
    mint: {
        WaitMessage: 'Please wait until MetaMask application window',
        ConfirmProcess: 'Please Confirm Minting Transaction',
        ProcessMessage: 'Please dont leave this page while minting process! It can take a few seconds.',
        SuccessMessage: 'Your minitng successfully has been done',
    },
    trans: {
        WaitMessage: 'Please wait until MetaMask application window',
        ConfirmProcess: 'Please Confirm Transfer Transaction',
        ProcessMessage: 'Please dont leave this page while transferring process! It can take a few seconds.',
        SuccessMessage: 'Your transfer successfully has been done',
    }
}

export const MintLoadingMessages = {
    WaitMessage: 'Please wait until MetaMask application window',
    ConfirmMintingTransaction: 'Please Confirm Minting Transaction',
    ProcessMessage: 'Please dont leave this page while minting process! It can take a few seconds.',
}
