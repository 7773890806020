import {useContext, useEffect} from 'react';
import { Navigate } from "react-router-dom";
import { clientStateActionTypes, GlobalStateContext } from "../../store/GlobalState";

export const ClearId = () => {

    const { dispatchClientState } = useContext(GlobalStateContext);

    useEffect(() => {
        dispatchClientState({
            type: clientStateActionTypes.ClearId,
        });
    },[]);

    return(
        <Navigate to='/' />
    );
}
