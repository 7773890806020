import styled from "@emotion/styled";
import priceBackground from "../../../../assets/images/mint/input-background.svg";
import priceBlueBackground from "../../../../assets/images/mint/input-background-blue.svg";
import {backgroundColors} from "../../../../config/backgroundConfig";

export const InputStyledBlock = styled.div`
  display: inline-block;
  padding: 0.7em 1.2em;
  background-image: ${props => props.secondary ? `url("${priceBlueBackground}")` : `url("${priceBackground}")`};
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  font-weight: bolder;
  
  &> span {
    color: ${backgroundColors.PurpleDefault};
  }
`;
