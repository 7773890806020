import {CollectionContainer} from "./CollectionContainer";

export const CollectionItem = ({item}) => {
    return (
        <>
            {
                item.url !== '' &&
                <CollectionContainer>
                    <a target='_blank' href={item.url} rel="noopener noreferrer">
                        <img src={item.img} srcSet={`${item.img2X} 2x, ${item.img3X} 3x`} alt={item.name}/>
                    </a>
                </CollectionContainer>
            }
            {
                item.url === '' &&
                <CollectionContainer>
                    <img src={item.img} srcSet={`${item.img2X} 2x, ${item.img3X} 3x`} alt={item.name}/>
                </CollectionContainer>
            }
        </>

    );
}
