import {useContext} from "react";
import Web3 from "web3";
import {clientStateActionTypes, GlobalStateContext, viewStateActionTypes} from "../store/GlobalState";
import {ModalTypes} from "../config/modalConfig";

export const UseEthConnect = () => {

    const { clientState, dispatchClientState, dispatchViewState } = useContext(GlobalStateContext);

    const loginClient = async () => {

        if (window.ethereum) {
            window.ethereum.request({
                method: "eth_requestAccounts",
            }).then((event) => {
                // console.log('login', event);
            }).catch((error) => {
                if (error.code === -32002) {
                    dispatchViewState({
                        type: viewStateActionTypes.OpenAppModal,
                        payload: {
                            type: ModalTypes.MessageModal,
                            title: 'Please',
                            message: 'open and login your metamask extension.',
                        }
                    });
                }
                // console.log('error', error);
            });
            window.web3 = new Web3(window.ethereum);

            const accounts = await window.web3.eth.getAccounts();
            const networkId = await window.web3.eth.net.getId();

            if (accounts.length>0 && networkId) {
                if (clientState.isLoggedIn !== true) {
                    dispatchClientState({
                        type: clientStateActionTypes.SetLoggedIn,
                        payload: true,
                    });
                }
                dispatchClientState({
                    type: clientStateActionTypes.SetEthAccount,
                    payload: {
                        account: accounts[0],
                        networkId: networkId,
                    }
                });
                dispatchViewState({type: viewStateActionTypes.CloseAppLoader});
            }
            else {
                dispatchClientState({
                    type: clientStateActionTypes.ClearLoggedIn
                });
                dispatchViewState({type: viewStateActionTypes.CloseAppLoader});
            }
        }
    };

    const handleConnect = async () => {
        loginClient();
    }

    const checkWalletIsConnected = async (onConnected) => {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({
                method: "eth_accounts",
            });

            if (accounts.length > 0) {
                const account = accounts[0];
                onConnected(account);

            }
        }
    }

    return({
        handleConnect,
        checkWalletIsConnected,
    });
}
