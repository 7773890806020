export const accordionData = [
    {
        id: '1',
        title: 'How do I buy a Boogie Club Pass (Bored Toilet Paper Club) NFT?',
        description: 'You will need a web3 wallet (example: Metamask) with Ethereum funds, and you will connect it to our official website: https://ps.bmlc.io/ , connect your wallet and mint the NFT token. It will require a transaction to happen.',
    },{
        id: '2',
        title: 'What are gas fees?',
        description: 'Gas fees are payments made by users to compensate for the computing energy required to process and validate transactions on the Ethereum blockchain. Each transaction requires this kind of payment.',
    },{
        id: '3',
        title: 'How many will I be able to mint?',
        description: 'One per wallet',
    },{
        id: '4',
        title: 'Does BTPC NFT have a rarity?',
        description: 'Yes, depending on the combination of your traits, your NFT may be more rare than others',
    },
    {
        id: '5',
        title: 'How can I get a Cryptocurrency Wallet?',
        description: 'Sign Up for a wallet like Metamask, which is where you can store and control your cryptocurrency and digital assets.',
    },
    {
        id: '6',
        title: 'How can I buy cryptocurrency?',
        description: 'Join a cryptocurrency marketplace like Coinbase and fund your account with Ethereum.',
    },
    {
        id: '7',
        title: 'How can I transfer my cryptocurrency?',
        description: 'Transfer your ETH coins from Coinbase to your wallet (example: Metamask), which gives you control to purchase NFTs across the various marketplaces.',
    },
    {
        id: '8',
        title: 'Can I transfer my Boogie Club Pass (Toilet paper faces) to someone?',
        description: 'Yes you can.  But if you don\'t have any you\'re automatically off of community. And If you didn\'t get your 2nd gift toilet paper yet, the owner of your 1st toilet paper will get your gift, so it is better to wait a short time to be sure your gift reaches you, after you can do transfer what toilet paper you want.',
    },
    {
        id: '9',
        title: 'When I\'ll receive my FREE gift NFT?',
        description: 'You should get your TPF gift when someone mint another TPF right after you.',
    },
    {
        id: '10',
        title: 'Attention',
        description: 'We\'re not financial advisers. By entering our club, you confirm that you will not use your participation in the club and the project as a whole, in any monetary fraud or other illegal financial transactions',
    }
];