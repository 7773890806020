import React from 'react';
import { PrimarySection } from '../../shared/Section';
import {HelpDeskAccordion} from "./HelpDeskAccourdion";
import {Title} from "../../shared/Title";
import {backgroundColors} from "../../../config/backgroundConfig";

export const HelpDesk = () => {

    return(
        <>
            <PrimarySection
                backgroundColor="transparent"
                withoutContentBackground={true}
            >
                <Title>
                    <div
                        style={{
                            textAlign: 'left',
                            marginBottom: '1em',
                            marginLeft: '1em',
                            marginTop: '2em',
                        }}
                    >
                        <span style={{color: backgroundColors.PinkLight}}>HELP</span>DESK
                    </div>
                </Title>
            </PrimarySection>
            <PrimarySection
                backgroundColor="transparent"
                withoutContentBackground={true}
            >
                <HelpDeskAccordion />
            </PrimarySection>
        </>
    );
};
