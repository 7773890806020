import styled from '@emotion/styled';

export const ArrowContainer = styled.div`
  
  margin: 0 auto;
  max-width: 800px;
  
  & > img {
    margin-top: 1em;
    width: 70px;
    margin-left: 30vw;
  }
  
  @media (min-width: 800px) {
    & > img {
      margin-left: 240px;
    }
  }
  
  @media (max-width: 500px) {
    & > img {
      width: 70px;
      margin-left: 0;
    }
  }
`
