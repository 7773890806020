export const rewardPoints = [
    {
        id: '1',
        checkBoxName: 'nftFree',
        title: '1 nft for free',
        description: 'When you mint 1 Toilet Paper NFT, you get 1 for free',
        isHaveEmailInput: false,
        isHaveSocialIcons: false,
    },
    {
        id: '2',
        checkBoxName: 'reserved',
        title: 'reserved spot',
        description: 'Guaranteed reserved spot on the BoogieMoon whitelist',
        isHaveEmailInput: false,
        isHaveSocialIcons: false,
    },
    {
        id: '3',
        checkBoxName: 'early',
        title: 'early access',
        description: 'Early member-only access to the BoogieMoon sale that ll last only 7 days',
        isHaveEmailInput: true,
        isHaveSocialIcons: false,
    },
    {
        id: '4',
        checkBoxName: 'community',
        title: 'Access to Community',
        description: 'Community of only 1000 first come first serve members with our team when you get to influence the development of the BoogieMoon project',
        isHaveEmailInput: false,
        isHaveSocialIcons: true,
    },
    {
        id: '5',
        checkBoxName: 'reward',
        title: 'Reward in the future',
        description: 'Smart contract-guaranteed reward in the form of a free NFT in our second secret phase of Boogie Moon project development.\n 1 Toilet Paper + 1 BoogiMoons = 1 WC',
        isHaveEmailInput: false,
        isHaveSocialIcons: false,
    }
];
