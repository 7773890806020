import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: rgba(0,0,0,0.5);
  z-index: 800;
  display: flex;
  justify-content: center;
  align-items: center;
`
