import styled from '@emotion/styled';
import {backgroundColors} from "../../../../config/backgroundConfig";

export const Container = styled.div`
  padding: 1.5em;
  border-radius: 5px;
  overflow: hidden;
  background-color: ${backgroundColors.PurpleDefault};
  
  &> h1 {
    padding-top: 0;
    margin-top: 0;
  }
`
