import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import App from './App';
import {gTagId} from "./config/websiteConfig";
import reportWebVitals from './reportWebVitals';
import isLocalHost from "./utils/isLocalHost";

if (!isLocalHost()) {
    TagManager.initialize({
        gtmId: gTagId,
        dataLayerName: 'PageDataLayer',
    });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
