import styled from '@emotion/styled';

export const Container = styled.div`
  padding: 2em;
  overflow: hidden;
  background-color: transparent;
  text-align: center;
  
  &> h1 {
    padding-top: 0;
    margin-top: 0;
  }
`
