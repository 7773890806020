import styled from "@emotion/styled";

export const Hint = styled.div`
  margin-top: 1em;
  
  @media (max-width: 600px) {
    width: 80%;
    margin: 1em auto 0;
  }
`;
