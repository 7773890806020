import section1_1x from '../../../assets/images/umpcoming-collections/1x/bms.png';
import section2_1x from '../../../assets/images/umpcoming-collections/1x/wc.png';
import section3_1x from '../../../assets/images/umpcoming-collections/1x/tree.png';

import section1_2x from '../../../assets/images/umpcoming-collections/2x/bms.png';
import section2_2x from '../../../assets/images/umpcoming-collections/2x/wc.png';
import section3_2x from '../../../assets/images/umpcoming-collections/2x/tree.png';

import section1_3x from '../../../assets/images/umpcoming-collections/3x/bms.png';
import section2_3x from '../../../assets/images/umpcoming-collections/3x/wc.png';
import section3_3x from '../../../assets/images/umpcoming-collections/3x/tree.png';

export const upcomingCollectionObjects = [
    {
        id: '1',
        name: 'upcoming project 1',
        url: 'https://bmlc.io/',
        img: section1_1x,
        img2X: section1_2x,
        img3X: section1_3x,
    },
    {
        id: '2',
        name: 'upcoming project 2',
        url: '',
        img: section2_1x,
        img2X: section2_2x,
        img3X: section2_3x,
    },
    {
        id: '3',
        name: 'upcoming project 3',
        url: '',
        img: section3_1x,
        img2X: section3_2x,
        img3X: section3_3x,
    }
]
