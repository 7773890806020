import styled from "@emotion/styled";

export const BottomGrid = styled.div`
  
  color: white;
  margin-top: -300px;
  
  @media (min-width: 601px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2em;
  }

  @media (max-width: 600px) {
    margin-top: -320px;
    display: flex;
    flex-direction: column-reverse;
  }
`;
