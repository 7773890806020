import styled from '@emotion/styled';

export const Container = styled.div`
  position: sticky;
  top: 0;
  margin: 0;
  z-index: 100;
  width: 100%;
  height: 60px;
  overflow: hidden;
  display: flex;
  padding: 1em;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.primary.backgroundHeader};
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0px rgb(0 0 0 / 14%), 0 1px 10px 0px rgb(0 0 0 / 12%);
  
  &> .left-block {
    display: flex;
    flex: 1;
    justify-content: flex-start;
  }
  
  &> .logotype {
    display: flex;
    flex: 1;
    justify-content: center;
    height: 100%;
    
  }
  &> .connect-wrapper {
    display: flex;
    font-size: 0.8em;
    text-align: right;
    flex: 1 1;
    justify-content: flex-end;
    
    &> div .error-message {
      font-size: 0.8em;
      color: #FF0000;
    }
  }
  
  @media(max-width: 700px) {
    &> .left-block {
      display: none;
    }
    &> .logotype {
      height: 80%;
    }
  }
  
  @media(max-width: 375px) {
    &> .left-block {
      display: none;
    }
    &> .logotype {
      height: 50%;
    }
  }
`
