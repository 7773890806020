import React from "react";
import {Button} from "./components/Button";

export const SecondarySubmitButton = (props) => {

    const {onClick, children} = props;

    return(
        <Button
            { ...props}
            onClick={() => {
                if (onClick) {
                    onClick();
                }
            }}
            style={{
                fontSize: '1em',
                ...props.style,
            }}
        >{children}</Button>
    );
}
