import localState from '../../services/localState';
import { DeviceTypes } from '../../config/uiConfig';
import {PROCESS_ACTION_TYPES} from "../../config/actionConfig";

export const initialProcessState = {
    type: PROCESS_ACTION_TYPES.Ready,
    id: undefined,
    transferId: undefined,
    message: '',
};

export const initialClientState = {
    localId: localState.getLocalId(),
    isLoggedIn: localState.getLoggedIn(),
    bc: {
        account: '',
        networkId: null,
        connectedWalletBool: false,
    },
    smart: {},
    isHaveNFT: false,
};

export const initialViewState = {
    deviceSize: {
        width: 0,
        height: 0,
    },
    deviceType: DeviceTypes.Desktop,
    pages: {
        isFirstVisit: localState.getIsFirstVisit(),
        isReadDocumentation: localState.getIsReadDescription(),
    },
    modal: {
        isOpen: false,
    },
    loaders: {
        appLoader: true,
        appPreloading: true,
    },
};

export const initialRewardState = {
    reward: false,
    community: false,
    early: false,
    reserved: false,
    nftFree: false,
};

export const initialPreloadState = {
};
