import React from "react";
import {socials} from "../../../config/websiteConfig";
import {Container} from "./Container";

export const SocialBlock = ({iconWidth = 40, iconHeight = 40}) => {

    return(
        <Container className='social-icons'>
            {
                socials.map((item, key) => (
                    <div key={`${Math.random()*100/key}-${item.name}---${key}`}>
                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                                <img src={item.icon} style={{width: iconWidth, height: iconHeight}} alt={item.alt} />
                            </a>
                    </div>
                ))
            }
        </Container>
    )
}
