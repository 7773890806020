import React from 'react';
import {backgroundColors} from '../../../config/backgroundConfig';
import {Title} from '../../shared/Title';
import { PrimarySection } from '../../shared/Section';
import {rewardPoints} from "./constants";
import {DescriptionBlock} from "./components/DescriptionBlock";
import topLeftIcon from '../../../assets/images/rewards/left-top-icon.svg';
import bottomRightIcon from '../../../assets/images/rewards/bottom-right-icon.svg';
import {TopLeftWrapper} from "./components/TopLeftWrapper";
import {BottomRightWrapper} from "./components/BottomRightWrapper";
import {Divider} from "./components/Divider";
import {DividerSection} from "../DividerSection";

export const RewardSection = () => {

    return(
        <>
            <DividerSection
                zIndex={2}
                minHeight="10em"
                marginTop="-8em"
            />
            <PrimarySection
                backgroundColor="#20093a"
                contendBackgroundColor={backgroundColors.BlueDefault}
                marginTop='-4em'
                paddingBottom='2em'
            >
                <TopLeftWrapper>
                    <img src={topLeftIcon} alt='top left' />
                </TopLeftWrapper>
                <Divider />
                <Title style={{padding: '1em'}}>
                    <span style={{color: backgroundColors.PurpleDefault}}>The only</span> toilet paper <br/>
                    you'll want to keep!
                </Title>
                <>
                    {
                        rewardPoints.map((item, key) => (
                            <DescriptionBlock
                                key={`${item.id}_${key}`}
                                item={item}
                            />
                        ))
                    }
                </>
                <div />
                <BottomRightWrapper>
                    <img src={bottomRightIcon} alt='bottom right' />
                </BottomRightWrapper>
            </PrimarySection>
            <DividerSection
                marginTop="-2em"
            />
        </>
    );
}
